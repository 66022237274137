import React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const ConditionalAddCircleButton = ({ to, condition }) => {
  if (!condition) {
    return null;
  }

  return (
    <Tooltip title="Add">
      <IconButton component={Link} to={to}>
        <AddCircleIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ConditionalAddCircleButton;
