import cbSheetHandler from "./CBSheetHandler";
import defaultHandler from "./DefaultHandler";
import gatePassHandler from "./GatePassHandler";
import materialInwardHandler from "./MaterialInwardHandler";
import materialOutwardHandler from "./MaterialOutwardHandler";
import { EntityHandler } from "./types";
import userHandler from "./UserHandler";
import myProfileHandler from "./MyProfileHandler";
import itemHandler from "./ItemHandler";

const handlerMap = new Map<string, EntityHandler>();
handlerMap.set("cb-sheet", cbSheetHandler);
handlerMap.set("gate-pass", gatePassHandler);
handlerMap.set("material-outward", materialOutwardHandler);
handlerMap.set("material-inward", materialInwardHandler);
handlerMap.set("user", userHandler);
handlerMap.set("my-profile", myProfileHandler);
handlerMap.set("item", itemHandler);

export function getHandler(entityType: string): EntityHandler {
  if (handlerMap.has(entityType)) {
    return handlerMap.get(entityType);
  }
  return defaultHandler;
}
