import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { formatDate } from "../services/dateUtils";
import { projectTypeValues } from "./project";

export const workTypeValues = [
  { value: "BBW", label: "Back Bone" },
  { value: "LMW", label: "Last Mile" },
  { value: "ICW", label: "Inter City" },
  { value: "FTTB", label: "FTTB" },
  { value: "OVH", label: "Over Head" },
  { value: "UGD", label: "Under Ground" },
  { value: "MIX", label: "Mixed" },
  { value: "IBD", label: "IBD" },
  { value: "TPT", label: "TPT" },
  { value: "RHB", label: "Rehab" },
  { value: "DHK", label: "De-Choking" },
  { value: "CTV", label: "Cut Over" },
  { value: "OTH", label: "Others" },
];

export const workFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "othOwnerStates",
        defaultValue: null,
        props: {
          width: "17vw",
        },
        disability: {
          type: "IN",
          field: "foreignFlg",
          values: [true],
        },
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "stateCode",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
        ],
        linkedFields: [
          {
            name: "siteId",
            value: "",
          },
          {
            name: "projectName",
            value: "",
          },
          {
            name: "projectCode",
            value: "",
          },
          {
            name: "projectType",
            value: "",
          },
          {
            name: "ownerCode",
            value: "",
          },
          {
            name: "locatorName",
            value: "",
          },
          {
            name: "locatorCode",
            value: "",
          },
          {
            name: "companyName",
            value: "",
          },
          {
            name: "companyCode",
            value: "",
          },
        ],
      },
      {
        name: "stateCode",
        label: "State Code",
        type: "text",
        defaultValue: null,
        autoFilled: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "lookup",
    lookupType: "project",
    lookupCategory: "workProjects",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "stateCode",
        },
        {
          type: "IN",
          field: "projectType",
          values: ["REG"],
        },
      ],
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "projectCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "projectType",
        fieldName: "projectType",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "ownerCode",
        fieldName: "ownerCode",
      },
    ],
    linkedFields: [
      {
        name: "siteId",
        value: "",
      },
      {
        name: "locatorName",
        value: "",
      },
      {
        name: "locatorCode",
        value: "",
      },
      {
        name: "companyName",
        value: "",
      },
      {
        name: "companyCode",
        value: "",
      },
    ],
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "stateName",
        },
      ],
    },
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "6vw",
        },
      },
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        values: projectTypeValues,
        autoFilled: true,
        props: {
          width: "11vw",
        },
      },
      {
        name: "ownerCode",
        label: "Owner Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "siteId",
        label: "Site Id",
        type: "lookup",
        lookupType: "work-site",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "ownerCode",
            },
          ],
        },
        defaultValue: "",
        disability: {
          type: "OR",
          filters: [
            {
              type: "MISSING",
              field: "stateCode",
            },
            {
              type: "MISSING",
              field: "ownerCode",
            },
          ],
        },
        props: {
          width: "18vw",
        },
        dependentFields: [
          {
            additionalFieldName: "details",
            lookupFieldName: "id",
            fieldName: "siteType",
          },
        ],
      },
      {
        name: "siteType",
        label: "Site Type",
        type: "text",
        autoFilled: true,
        defaultValue: null,
        props: {
          width: "6vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "workType",
        label: "Work Type",
        type: "picklist",
        allowUpdate: true,
        defaultValue: "",
        values: workTypeValues,
        props: {
          width: "12vw",
        },
      },
      {
        name: "poNo",
        label: "PO number",
        type: "text",
        allowUpdate: true,
        allowNull: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "companyName",
        label: "Company Name",
        type: "lookup",
        lookupType: "company",
        allowNull: false,
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "contractorFlg",
              values: [true],
            },
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "companyCode",
            },
            {
              type: "EQUALS",
              field: "active",
              values: true,
            },
          ],
        },
        props: {
          width: "17vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "companyCode",
          },
        ],
        linkedFields: [
          {
            name: "locatorCode",
            value: "",
          },
          {
            name: "locatorName",
            value: "",
          },
        ],
        disability: {
          type: "EXISTS",
          field: "details",
        },
      },
      {
        name: "companyCode",
        label: "Company",
        type: "text",
        autoFilled: true,
        defaultValue: null,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "locatorCode",
        label: "Locator",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
      },
      {
        name: "locatorName",
        label: "Locator Name",
        props: {
          width: "17vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "locatorType",
              values: ["CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "locatorCode",
            },
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "companyCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "locatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "todayDate",
            fieldName: "workDate",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "MISSING",
              field: "companyCode",
            },
            {
              type: "MISSING",
              field: "stateCode",
            },
          ],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "remarks",
        label: "Remarks",
        type: "text",
        allowUpdate: true,
        allowNull: true,
        props: {
          width: "25vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "id",
        label: "Work Id",
        type: "number",
        autoFilled: true,
        allowNull: true,
      },
      {
        name: "workDate",
        label: "Work Date",
        type: "date",
        displayOnly: true,
        // defaultValue: "",
        // constraints: [
        //   {
        //     type: "DATE_RANGE",
        //     minDateScript: {
        //       operation: "minDate",
        //       globalFields: [SYSTEM_START_DATE_FIELD],
        //     },
        //     beforeNow: true,
        //   },
        // ],
      },
    ],
  },
  {
    name: "section4",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: false,
        displayOnly: true,
      },
    ],
  },
];

export const workColumns = [
  {
    name: "id",
    label: "Work Id   ",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "workType",
    label: "Work Type",
    type: "text",
    format: (value) => translatePicklistValue(value, workTypeValues),
  },
  // {
  //   name: "poNo",
  //   label: "PO Number",
  //   type: "text",
  // },
  {
    name: "workDate",
    label: "Work Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workExcelColumns = [
  {
    name: "id",
    label: "Work Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "workType",
    label: "Work Type",
    type: "text",
    format: (value) => translatePicklistValue(value, workTypeValues),
  },
  {
    name: "workDate",
    label: "Work Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "poNo",
    label: "PO Number",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workSearchColumns = [
  {
    name: "id",
    label: "Work Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "workType",
    label: "Work Type",
    type: "picklist",
    defaultValue: "",
    values: workTypeValues,
  },
  {
    name: "work.projectCode",
    label: "Project Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "work.locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
