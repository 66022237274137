import { DefaultHandler } from "./DefaultHandler";
class ItemHandler extends DefaultHandler {
  hasDataImport(): boolean {
    return false;
  }
  getEntityType(): string {
    return "item";
  }
}
const itemHandler = new ItemHandler();
export default itemHandler;
