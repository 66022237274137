import React, { useState, useRef } from "react";
import "./FileUploadComponent.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Snackbar from "@mui/material/Snackbar";
import { IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiCall } from "../../services/api";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from '@mui/icons-material/Cancel';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButtonComponent = ({ uploadDisabled = false, handleFileUpload = (file) => {}, isDuplicateFileName = (fileName) => {}, entityType }) => {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];

    // Check if the file is a PDF
    if (isValidFileType(selectedFile)) {
      setSnackBarMessage("Please upload a PDF file.");
      setFile(null);
      return;
    }

    // Allow only one file to be uploaded, replace the existing file
    setFile(selectedFile);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];

    // Check if the file is a PDF
    if (isValidFileType(selectedFile)) {
      setSnackBarMessage("Please upload a PDF file.");
      setFile(null);
      return;
    }

    setFile(selectedFile);
    setIsDragOver(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      handleRemoveFile();
    }, 500);
  };

  const handleSnackBarClose = () => {
    setSnackBarMessage(null);
  };

  const handleSubmit = async (event, file) => {
    event.preventDefault();

    try {
      if (file.size > 5 * 1024 * 1024) {
        setSnackBarMessage("File size exceeds the 5 MB limit");
        return;
      }

      if (isDuplicateFileName(file.name)) {
        setSnackBarMessage("File with the same name already uploaded for this document");
        return;
      }
      // Create FormData object to send file to backend
      const formData = new FormData();
      formData.append("file", file);
      formData.append("entityType", entityType);

      setLoading(true);
      // Send file to backend
      const response = await apiCall("post", `/api/file/cloudUpload`, formData, {
        "Content-Type": "multipart/form-data",
      });
      setSnackBarMessage(`File uploaded successfully. Save the document to attach it to this document.`);
      handleFileUpload(response);
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackBarMessage("File upload failed with error: " + error.message);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const isValidFileType = (selectedFile) => {
    return selectedFile && selectedFile.type !== "application/pdf";
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Button onClick={handleOpen} variant="contained" disabled={snackBarMessage} startIcon={<AttachFileIcon />}>
        Attachment
      </Button>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message={snackBarMessage}
        action={action}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <div
            className={`file-upload-container ${isDragOver ? "drag-over" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <Stack direction="column" alignItems="center" spacing={4}>
              {file && (
                <Stack direction="row" alignItems="center" spacing={7}>
                  <PictureAsPdfIcon fontSize="large" sx={{ color: "red" }} />
                  {file.name}
                  <IconButton aria-label="delete" size="small" onClick={handleRemoveFile}>
                    <CancelIcon fontSize="medium" />
                  </IconButton>
                </Stack>
              )}
              {!uploadDisabled && !file && (
                <Stack direction="column" alignItems="center" spacing={4}>
                  <CloudUploadIcon color="primary" fontSize="large" />
                  <div>
                    <p>
                      <b>Drag and Drop File Here!</b>
                    </p>
                    <p>Upload PDF File</p>
                  </div>
                  <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput ref={fileInputRef} type="file" accept="application/pdf" onChange={handleFileSelection} />
                  </Button>
                </Stack>
              )}
            </Stack>
          </div>
        </DialogContent>
        <DialogActions>
          {!loading ? (
            file ? (
              <>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={(e) => handleSubmit(e, file)}>
                  Upload
                </Button>
              </>
            ) : (
              <Button onClick={handleClose}>Close</Button>
            )
          ) : (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadButtonComponent;
