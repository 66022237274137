import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { translatePicklistValue } from "../../services/fieldsUtils";
import {
  stockReportCategoryValues,
  getStockReportFormFields,
  stockItemSummaryExcelColumns,
  // stockInwardSummaryExcelColumns,
  stockFullDetailExcelColumns,
} from "./formFields/stockListingFormFields";
import { getWorkStartDate, getToday } from "../../services/dateUtils";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.reportCategory];
};

const reportCategoryToExcelColumns = {
  ITEM_SUMMARY: stockItemSummaryExcelColumns,
  // INWARD_SUMMARY: stockInwardSummaryExcelColumns,
  FULL_DETAIL: stockFullDetailExcelColumns,
};

const getReportName = (searchRequest) => {
  return translatePicklistValue(searchRequest.reportCategory, stockReportCategoryValues);
};

const StockReportForm = ({ formMinWidth = "40vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={getStockReportFormFields()}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Listing Reports"}
      getReportName={getReportName}
      reportType={"stock"}
      preProcessReportSearchRequest={(searchRequest) => {
        searchRequest.fromDocDate = searchRequest.fromDocDate ? searchRequest.fromDocDate : getWorkStartDate();
        searchRequest.toDocDate = searchRequest.toDocDate ? searchRequest.toDocDate : getToday();
        searchRequest.stockDate = searchRequest.stockDate ? searchRequest.stockDate : getToday();
        return searchRequest;
      }}
    />
  );
};

export default withAuth(StockReportForm);
