import { Dayjs } from "dayjs";
import { getWorkStartDate } from "../../services/dateUtils";
import { isTransactionEntity } from "../../services/standardEntityFieldService";
import { EntityHandler, HandleEntitySpecificChangeDTO } from "./types";
import { Dispatch, SetStateAction } from "react";

export class DefaultHandler implements EntityHandler {
  hasDataImport(): boolean {
    return false;
  }
  getFormWidth(): string {
    return "40vw";
  }
  formDisabled(record: any): boolean {
    return false;
  }
  handleEntitySpecificChange(handleEntitySpecificChangeDTO: HandleEntitySpecificChangeDTO): Promise<void> {
    return;
  }
  isValidRecord(record: any, setFormErrors: Dispatch<SetStateAction<{}>>, setError: Dispatch<SetStateAction<{}>>): boolean {
    return true;
  }

  isUnSubmitRequired = (isApproved: boolean) => {
    return isApproved;
  };
  canDelete = (recordType, currentUser, record) => !isTransactionEntity(recordType);

  getViewDefaultStartDate(): Dayjs {
    return getWorkStartDate();
  }

  getEntityType(): string {
    throw new Error("Unsupported function for default handler");
  }
}

const defaultHandler = new DefaultHandler();
export default defaultHandler;
