import _, { isEmpty, isNumber } from "lodash";
import { evaluateFilter } from "./filterEvaluator";
import { formatDate, getMinMaxDateConstraint } from "./dateUtils";
import { adminEntities, isTransactionEntity } from "./standardEntityFieldService";
import { User, UserType } from "../components/users/UserTypes";
import { Filter } from "../types/filter";
import { Constraint } from "../types/constraint";
import { ValueScript } from "../types/valueScript";
import { getConfigs, getConfigValue } from "../config/configHolder";
import { Dispatch, SetStateAction } from "react";
import { Field, SearchParam } from "../types/field";
import { getAccessibleLocatorCodes } from "../config/configHolder";
import { FilteredValue, LinkedField } from "../types/field";

export const getRecordsSearchRequestFilters = (record: any, filter: Filter, globalConstants: any) => {
  if (!filter) {
    return {};
  }

  let newFilter = JSON.parse(JSON.stringify(filter));
  let { type, filters } = newFilter;

  switch (type) {
    case "AND":
      let filterConditions = [];
      if (!filters) {
        return {};
      }
      for (let filter of filters) {
        filterConditions.push(getRecordsSearchRequestFilters(record, filter, globalConstants));
      }
      newFilter.filters = filterConditions;
      return newFilter;
    default:
      return getRecordsSearchRequestFiltersInternal(record, newFilter, globalConstants);
  }
};

export const getRecordsSearchRequestFiltersInternal = (record: any, filter: any, globalConstants: any) => {
  let { type, field, resolveValue, valueFieldName } = filter;
  if (resolveValue) {
    let values = resolveValueInternal(valueFieldName, record, globalConstants);
    return { field, values, type };
  }
  return filter;
};

export const getRecordSearchParams = (record: any, searchParams: SearchParam[], globalConstants: any) => {
  if (!searchParams || _.isEmpty(searchParams)) {
    return searchParams;
  }
  let newSearchParams = {};
  searchParams.forEach((param) => {
    let finalValues = [];
    let { key, resolveValue, valueFieldName, values } = param;
    if (resolveValue) {
      finalValues = resolveValueInternal(valueFieldName, record, globalConstants);
    } else {
      finalValues = values;
    }
    newSearchParams[key] = finalValues;
  });
  return newSearchParams;
};

export function resolveValueInternal(valueFieldName: string, record: any, globalConstants: any[]): string[] | number[] {
  let context = { ...record, ...globalConstants, ...getConfigs() };
  if (context && typeof context[valueFieldName] != "undefined") {
    const value = context[valueFieldName];
    return Array.isArray(value) ? value : [value];
  }
  return [];
}

export const resolveLinkedFieldValue = (childField: LinkedField, currentContext) => {
  let { value, filteredValues, resolveValue, valueFieldName } = childField;
  let selectedValue = value;
  if (resolveValue) {
    let resolvedValues = resolveValueInternal(valueFieldName, currentContext, []);
    selectedValue = resolvedValues ? resolvedValues[0] : "";
  }
  if (filteredValues) {
    let selectedFilteredValue: FilteredValue = filteredValues.find((filteredValue: FilteredValue) => {
      return evaluateFilter(filteredValue.filter, currentContext);
    });
    if (selectedFilteredValue) {
      let { valueFieldName, value, resolveValue } = selectedFilteredValue;
      selectedValue = value;
      if (resolveValue) {
        let resolvedValues = resolveValueInternal(valueFieldName, currentContext, []);
        selectedValue = resolvedValues ? resolvedValues[0] : "";
      }
    }
  }
  return selectedValue;
};

export function evaluateValueScript(valueScript: ValueScript, record, globalConstants = {}) {
  let { operation, valueFields, globalFields, fieldToSum, valueConfigs } = valueScript;
  let result: any = 0;

  switch (operation) {
    case "add":
      valueFields.forEach((element) => {
        let fieldValue = parseFloat(record[element]);
        if (!isNaN(fieldValue)) {
          result += fieldValue;
        }
      });
      result = isNaN(result) ? "" : result.toString();
      break;
    case "summation":
      valueFields.forEach((element) => {
        let fieldData = record[element];
        if (Array.isArray(fieldData)) {
          let oldFieldData = [...fieldData];
          oldFieldData.forEach((item) => {
            let oldItem = { ...item };
            if (typeof oldItem === "object" && oldItem !== null) {
              result += parseFloat(oldItem[fieldToSum] || 0);
            } else if (!isNaN(oldItem)) {
              result += parseFloat(oldItem);
            }
          });
        }
      });
      result = isNaN(result) ? "" : result.toString();
      break;

    case "divide":
      let dividend = parseFloat(record[valueFields[0]]);
      let divisor = parseFloat(record[valueFields[1]]);
      if (isNaN(dividend) || isNaN(divisor) || divisor === 0) {
        return "";
      }
      let divideResult = dividend / divisor;
      result = isNaN(divideResult) ? "" : divideResult.toFixed(4).toString();
      break;

    case "multi":
      let multiResult: any = 1;
      if (!isEmpty(valueFields)) {
        valueFields.forEach((element) => {
          if (!record[element]) {
            multiResult = 0;
            return;
          }
          let fieldValue = parseFloat(record[element]);
          if (!isNaN(fieldValue)) {
            multiResult *= fieldValue;
          } else {
            multiResult = 0;
            return;
          }
        });
      }
      if (!_.isEmpty(globalFields) && multiResult !== 0) {
        globalFields.forEach((element) => {
          let fieldValue = globalConstants && globalConstants[element] ? parseFloat(globalConstants[element]) : "";
          if (isNumber(fieldValue)) {
            multiResult *= fieldValue;
          } else {
            multiResult = 0;
          }
        });
      }
      result = isNaN(multiResult) ? "" : multiResult.toFixed(2).toString();
      break;
    case "min": {
      let value1 = getValue(valueFields[0]);
      let value2 = getValue(valueFields[1]);
      result = value1 && value1 < value2 ? value1 : value2;
      break;
    }

    case "minDate":
    case "maxDate": {
      let values: any = !_.isEmpty(valueScript.values) ? [...valueScript.values] : [];
      // Get values from valueFields
      if (valueFields) {
        for (const element of valueFields) {
          let fieldValue: any = getValue(element);

          if (!isEmptyObj(fieldValue)) {
            values.push(new Date(fieldValue));
          }
        }
      }

      // Get values from globalFields
      if (globalFields) {
        for (const element of globalFields) {
          let globalValue: any = getGlobalValue(element);
          if (!isEmptyObj(globalValue)) {
            values.push(new Date(globalValue));
          }
        }
      }

      if (valueConfigs) {
        for (const element of valueConfigs) {
          let configValue = new Date(getConfigValue(element) as unknown as string);
          if (!isEmptyObj(configValue)) {
            values.push(new Date(configValue));
          }
        }
      }

      if (operation === "minDate") {
        // Find the minimum date
        let minDate = values[0];
        for (let i = 1; i < values.length; i++) {
          if (minDate === null || values[i] < minDate) {
            minDate = values[i];
          }
        }
        result = minDate;
      } else {
        // Find the maximum date
        let maxDate = values[0];
        for (let i = 1; i < values.length; i++) {
          if (maxDate === null || values[i] > maxDate) {
            maxDate = values[i];
          }
        }
        result = maxDate;
      }
      break;
    }
    //default operation is copy
    default:
      result = valueFields && valueFields[0] ? getValue(valueFields[0]) : globalFields && globalFields[0] ? getGlobalValue(globalFields[0]) : null;
      break;
  }

  return result;

  function getValue(valueFieldName) {
    return record && record[valueFieldName] ? [record[valueFieldName]] : "";
  }
  function getGlobalValue(globalFieldName) {
    return globalConstants && globalConstants[globalFieldName] ? [globalConstants[globalFieldName]] : "";
  }
}

export const toUpperCase = (e, field?) => {
  const { value, selectionStart, selectionEnd } = e.target;

  if (!field || field.type === "text" || typeof value === "string") {
    // Convert the value to uppercase without changing the cursor position
    const newValue = value.toUpperCase();

    // Only update if the value has actually changed to avoid unnecessary re-render
    if (value !== newValue) {
      e.target.value = newValue;

      // Restore the cursor position after setting the new value
      e.target.setSelectionRange(selectionStart, selectionEnd);
    }
  }
};

export const canChangeValue = (e, field, decimal = field ? field.decimal : false): boolean => {
  const { value }: { value: any } = { ...e.target };
  let canChange = true;
  if (field && field.type === "number") {
    return canChangeNumericValue(decimal, value);
  }
  return canChange;
};

export const canChangeNumericValue = (decimal, value): boolean => {
  // If the field is a number
  if (decimal) {
    // If it allows decimals
    if (!/^\d*\.?\d{0,2}$/.test(value) && value !== "") {
      return false;
    }
  } else {
    // If it doesn't allow decimals (only whole numbers)
    if (!/^\d+(\.0*|\.?)$/.test(value) && value !== "") {
      return false;
    }
  }
  return true;
};
// eslint-disable-next-line no-unused-vars
// const trimValue = (value) => {
//   // Remove special characters, trim whitespace, and convert to uppercase
//   const pattern = /[^\x20-\x7E]/g; // Pattern to match non-printable ASCII characters
//   return value.replace(pattern, "").trim().toUpperCase();
// };

export const isEmptyObj = (obj) => {
  return obj === undefined || obj == null || obj === "";
};

export const isValidRecord = ({
  record,
  leafFields,
  setError,
  setFormErrors,
  allowNullFields = false,
  isValidEntityRecord = (record, setFormErrors, setError) => {
    return true;
  },
}) => {
  let newFormErrors = {};
  let isValid = true;
  let constraintSatisfied = true;
  for (let field of leafFields) {
    let disabled = false;
    if (!_.isEmpty(field.disability)) {
      disabled = evaluateFilter(field.disability, record);
    }
    if (!_.isEmpty(field.visibility)) {
      disabled = disabled || !evaluateFilter(field.visibility, record);
    }
    if (field.type === "formTable") {
      newFormErrors[field.name] = [{}];
      if (!_.isEmpty(record[field.name])) {
        for (let [index, row] of record[field.name].entries()) {
          for (let tableField of field.tableFields) {
            if (
              !(disabled || tableField.allowNull || allowNullFields) &&
              (row[tableField.name] === undefined || row[tableField.name] === "" || row[tableField.name] === null)
            ) {
              //todo:remove this set
              if (!isEmpty(tableField.defaultValue)) {
                record[field.name][index][tableField.name] = tableField.defaultValue;
              } else {
                newFormErrors[field.name][index] = {};
                newFormErrors[field.name][index][tableField.name] = "Cannot be null";
                setError(`${tableField.label} cannot be empty`);
                isValid = false;
                break;
              }
            }
            if (isValid && tableField.constraints) {
              if (!isConstraintsSatisfied(tableField.constraints, row[tableField.name], tableField, setError, setFormErrors, record)) {
                constraintSatisfied = false;
                break;
              }
            }
          }
        }
      }
    }

    if (!isValid || !constraintSatisfied) {
      break;
    }
    if (!(disabled || field.allowNull || allowNullFields) && isEmptyObj(record[field.name])) {
      newFormErrors[field.name] = "Cannot be Empty";
      setError(`${field.label} cannot be empty`);
      isValid = false;
    }
    if (isValid && field.constraints) {
      if (!isConstraintsSatisfied(field.constraints, record[field.name], field, setError, setFormErrors, record)) {
        constraintSatisfied = false;
      }
    }

    if (!isValid || !constraintSatisfied) {
      break;
    }
  }
  if (!isValid) {
    setFormErrors(newFormErrors);
    return false;
  }
  if (!constraintSatisfied) {
    return false;
  }
  try {
    if (!isValidEntityRecord(record, setFormErrors, setError)) {
      console.warn("[Utils] Entity record is invalid");
      return false;
    }
  } catch (e) {
    console.error("Exception occured while validating record", e);
    setError("Invalid Record, please verify entered values");
    return false;
  }
  return true;
};

const isConstraintValid = (constraint: any, record: any) => {
  return !constraint.filter || evaluateFilter(constraint.filter, record);
};

export const isConstraintsSatisfied = (
  constraints: Constraint[],
  newValue: any,
  currentField: Field,
  setError: Dispatch<SetStateAction<{}>>,
  setFormErrors: Dispatch<SetStateAction<{}>>,
  record: any
) => {
  let constraintSatisfied = true;
  constraints.forEach((constraint) => {
    if (isConstraintValid(constraint, record) && !isConstraintSatisfied(constraint, newValue, currentField.name, setFormErrors, record, setError)) {
      constraintSatisfied = false;
    }
  });
  return constraintSatisfied;
};

export const isConstraintSatisfied = (
  constraint: Constraint,
  newValue: any,
  fieldName: string,
  setFormErrors: Dispatch<SetStateAction<{}>>,
  record: any,
  setError: Dispatch<SetStateAction<string>> = () => {}
) => {
  if (!constraint) {
    return true;
  }
  switch (constraint.type) {
    case "RANGE": {
      if (isEmptyObj(newValue)) {
        return false;
      }
      let maxValue = constraint.maxValue;
      let maxLimit = constraint.maxLimit;
      if (constraint.maxValueScript) {
        let { maxValueScript } = constraint;
        maxValue = evaluateValueScript(maxValueScript, record);
      }
      if (!isEmptyObj(maxValue) && parseFloat(newValue) > maxValue) {
        setConstraintViolationErrors(`Value cannot be greater than: ${maxValue}`);
        return false;
      }
      if (!isEmptyObj(maxLimit) && parseFloat(newValue) >= maxLimit) {
        setConstraintViolationErrors(`Value cannot be greater than or equal to: ${maxLimit}`);
        return false;
      }
      let minValue = constraint.minValue;
      let minLimit = constraint.minLimit;
      if (constraint.minValueScript) {
        let { minValueScript } = constraint;
        minValue = evaluateValueScript(minValueScript, record);
      }
      if (!isEmptyObj(minValue) && parseFloat(newValue) < minValue) {
        setConstraintViolationErrors(`Value cannot be less than: ${minValue}`);
        return false;
      }
      if (!isEmptyObj(minLimit) && parseFloat(newValue) <= minLimit) {
        setConstraintViolationErrors(`Value cannot be less than or equal to: ${minLimit}`);
        return false;
      }
      return true;
    }
    case "DATE_RANGE": {
      let { minDate, maxDate } = getMinMaxDateConstraint(constraint, record);
      if (minDate && newValue && newValue < minDate) {
        let message = `Date cannot be less than: ${formatDate(minDate)}`;
        setConstraintViolationErrors(message);
        return false;
      }
      if (maxDate && newValue && newValue > maxDate) {
        let message = `Date cannot be greater than: ${formatDate(maxDate)}`;
        setConstraintViolationErrors(message);
        return false;
      }
      return true;
    }
    case "LENGTH": {
      let maxLength = constraint.maxLength || 0;
      if (constraint.maxLengthScript) {
        let { maxLengthScript, maxLimit } = constraint;
        maxLength = evaluateValueScript(maxLengthScript, record);
        maxLength = applyMaxLimit(maxLimit, maxLength);
      }
      if (newValue && newValue.length > maxLength) {
        let message = `Length cannot be greater than: ${maxLength}`;
        setConstraintViolationErrors(message);
        return false;
      }
      let minLength = constraint.minLength || 0;
      if (constraint.minLengthScript) {
        let { minLengthScript, minLimit } = constraint;
        minLength = evaluateValueScript(minLengthScript, record);
        minLength = applyMinLimit(minLimit, minLength);
      }
      if (newValue && newValue.length < minLength) {
        let message = `Length cannot be less than: ${minLength}`;
        setConstraintViolationErrors(message);
        return false;
      }
      return true;
    }
    default:
      return true;
  }

  function setConstraintViolationErrors(message) {
    setFormErrors((error) => {
      let newError = {};
      newError[fieldName] = message;
      return newError;
    });
    setError(message);
  }

  function applyMinLimit(minLimit, currentValue) {
    if (minLimit && (!currentValue || minLimit > currentValue)) {
      return minLimit;
    }
    return currentValue;
  }

  function applyMaxLimit(maxLimit, currentValue) {
    if (maxLimit && (!currentValue || maxLimit < currentValue)) {
      return maxLimit;
    }
    return currentValue;
  }
};

export const logStackTrace = () => {
  try {
    throw new Error("Stack trace");
  } catch (error) {
    console.error("Stack trace:", error.stack);
  }
};

export const getConstraintByType = (constraints, type, record) => {
  if (!constraints) {
    return null;
  }
  return constraints.find((constraint) => constraint.type === type && isConstraintValid(constraint, record));
};

export const canEditEntity = (entityType: string, currentUser: User) => {
  if (entityType === "my-profile") {
    return true;
  } else if (adminEntities.includes(entityType)) {
    return isAdminUser(currentUser) || currentUser.superUser;
  } else if (isTransactionEntity(entityType)) {
    return currentUser.transactionAccessType > 1;
  } else {
    return currentUser.masterAccessType > 1;
  }
};

export const canViewEntity = (entityType: string, currentUser: User) =>
  adminEntities.includes(entityType)
    ? isAdminUser(currentUser) || currentUser.superUser
    : isTransactionEntity(entityType)
    ? currentUser.transactionAccessType > 0
    : currentUser.masterAccessType > 0;

export const isLocatorAccessible = (locatorCode: string) => {
  if (!locatorCode) {
    return true;
  }
  let accessibleLocatorCodes = getAccessibleLocatorCodes();
  if (!accessibleLocatorCodes) {
    return true;
  }
  return accessibleLocatorCodes.includes(locatorCode);
};

export const isAdminUser = (currentUser: User) => {
  return currentUser.userType === UserType.ADMIN;
};
