export interface PDFContentGenerator {
  generatePDFContent(record: any): PDFContentResponse;
  getRecordType(): string;
}

export abstract class AbstractPDFContentGenerator implements PDFContentGenerator {
  generatePDFContent(record: any): PDFContentResponse {
    let { title, senderReceiverData, dtHead, details, remarks, otherValues } = this.assemblePdfData(record);

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "center" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  }
  abstract getRecordType(): string;
  abstract assemblePdfData(record: any): AssembledPdfData;
}

export interface AssembledPdfData {
  title: string[][];
  senderReceiverData: string[][];
  dtHead: string[][];
  details: string[][];
  remarks: string[][];
  otherValues: string[][];
}

export interface PDFContentResponse {
  rows: any[];
  footer?: string;
}
