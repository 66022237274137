import { formatDate, OUTWARD_CREATION_MIN_DATE, WORK_START_DATE_FIELD } from "../services/dateUtils";
import { Field, PicklistValue } from "../types/field";
import { materialTypeValues } from "./materialInward";
import { translatePicklistValue } from "../services/fieldsUtils";
import { Filter } from "../types/filter";
import { locatorTypeValues } from "../standardEntities/locator";
import { outProjectTypeValues } from "./project";

export const outTransactionTypeValues: PicklistValue[] = [
  { value: "OT", label: "Owner Loc Tfr", visibility: { type: "NIN", field: "projectType", values: ["NFIM"] } },
  { value: "ST", label: "Supplier Loc Tfr", visibility: { type: "IN", field: "projectType", values: ["NFIM"] } },
  { value: "PS", label: "Partner Store Tfr", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "CS", label: "Contractor Store Tfr", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "IS", label: "Internal Shift", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "SL", label: "Stock Loss", visibility: { type: "IN", field: "withinState", values: [true] } },
];

const childWorkOrderRequiredFilter: Filter = {
  type: "AND",
  filters: [
    { type: "IN", field: "transactionType", values: ["CS"] },
    { type: "IN", field: "projectType", values: ["FTTH"] },
  ],
};

const notChildWorkOrderRequiredFilter: Filter = {
  type: "NOT",
  filters: [childWorkOrderRequiredFilter],
};

const childWorkOrderAllowFilter: Filter = {
  type: "AND",
  filters: [
    { type: "IN", field: "transactionType", values: ["CS", "PS", "IS", "SL"] },
    { type: "IN", field: "projectType", values: ["FTTH"] },
  ],
};

export const receiverDocTypeValues: PicklistValue[] = [
  { value: "PO", label: "Purchase Order", visibility: notChildWorkOrderRequiredFilter },
  { value: "LO", label: "Lease Order", visibility: notChildWorkOrderRequiredFilter },
  { value: "MR", label: "Material Request", visibility: notChildWorkOrderRequiredFilter },
  { value: "CO", label: "Child Work Order", visibility: childWorkOrderAllowFilter },
  { value: "NA", label: "N/A", visibility: notChildWorkOrderRequiredFilter },
];

export const referenceDocTypeValues = [{ value: "NA", label: "N/A" }];

export const senderDocTypeValues: PicklistValue[] = [
  { value: "SI", label: "Sales Invoice" },
  { value: "DC", label: "Delivery Challan" },
  {
    value: "CO",
    label: "Child Work Order",
    visibility: {
      type: "AND",
      filters: [
        { type: "IN", field: "docLocatoryType", values: ["CS"] },
        { type: "IN", field: "projectType", values: ["FTTH"] },
      ],
    },
  },
  { value: "NA", label: "N/A" },
];

export const materialOutwardDetailFields: Field[] = [
  {
    name: "sno",
    label: "SNo.",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.5,
    },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "lookup",
    lookupType: "item",
    lookupCategory: "outwardItem",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "itemId",
          resolveValue: true,
          valueFieldName: "itemId",
        },
      ],
    },
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
      },
      {
        key: "materialType",
        resolveValue: true,
        valueFieldName: "materialType",
      },
      {
        key: "projectCode",
        resolveValue: true,
        valueFieldName: "projectCode",
      },
      {
        key: "selectedMirRowIds",
        resolveValue: true,
        valueFieldName: "mirRowIds",
      },
      {
        key: "outwardDocDate",
        resolveValue: true,
        valueFieldName: "docDate",
      },
    ],
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "itemCode",
        fieldName: "itemCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "itemCustCode",
        fieldName: "itemCustCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "itemId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "uom",
        fieldName: "uom",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "decimalFlg",
        fieldName: "decimalFlg",
      },
    ],
    props: {
      widthFactor: 3,
    },
    linkedFields: [
      {
        name: "mirRowId",
        value: "",
      },
    ],
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "lookupTable",
    lookupType: "material-inward-dtl",
    lookupCategory: "outwardRow",
    defaultValue: "",
    props: {
      widthFactor: 1.4,
    },
    lookupFilter: {
      type: "AND",
      filters: [
        {
          field: "itemId",
          type: "EQUALS",
          resolveValue: true,
          valueFieldName: "itemId",
        },
        {
          field: "docLocatorCode",
          type: "EQUALS",
          resolveValue: true,
          valueFieldName: "docLocatorCode",
        },
        {
          field: "materialType",
          type: "EQUALS",
          resolveValue: true,
          valueFieldName: "materialType",
        },
        {
          field: "projectCode",
          type: "EQUALS",
          resolveValue: true,
          valueFieldName: "projectCode",
        },
        {
          field: "id",
          type: "NIN",
          resolveValue: true,
          valueFieldName: "mirRowIds",
        },
        {
          field: "docDate",
          type: "LTE",
          resolveValue: true,
          valueFieldName: "docDate",
        },
      ],
    },
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
      },
      {
        key: "materialType",
        resolveValue: true,
        valueFieldName: "materialType",
      },
      {
        key: "projectCode",
        resolveValue: true,
        valueFieldName: "projectCode",
      },
    ],
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "itemId",
        },
      ],
    },
    tableColumns: [
      {
        name: "mirLotDate",
        label: "MIR Lot Date",
        type: "date",
        format: (value) => formatDate(value),
      },
      {
        name: "mirLotId",
        label: "MIR Lot",
        type: "text",
      },
      {
        name: "id",
        label: "MIR Row Id",
        type: "text",
      },
      {
        name: "mirId",
        label: "MIR No",
        type: "text",
      },
      {
        name: "docDate",
        label: "MIR Date",
        type: "date",
        format: (value) => formatDate(value),
      },
      {
        name: "transactionType",
        label: "Transaction Type",
        type: "text",
      },
      {
        name: "availableQuantity",
        label: "Available Quantity",
        type: "text",
      },
    ],
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "mirId",
        fieldName: "mirId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "mirLotId",
        fieldName: "mirLotId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "mirLotDate",
        fieldName: "mirLotDate",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "availableQuantity",
        fieldName: "mirAvailableQuantity",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "serialNo",
        fieldName: "mirSerialNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "partSerialNo",
        fieldName: "partSerialNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "rate",
        fieldName: "rate",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "batchNo",
        fieldName: "batchNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "availableQuantity",
        fieldName: "availableQuantity",
      },
    ],
  },
  {
    name: "mirLotId",
    label: "MIR Lot",
    type: "text",
    autoFilled: true,
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
    autoFilled: true,
  },
  {
    name: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    allowNull: true,
    autoFilled: true,
  },
  {
    name: "totalQuantity",
    label: "Out Quantity",
    type: "number",
    defaultValue: 0,
    allowUpdate: false,
    decimalFlg: true,
    constraints: [{ type: "RANGE", maxValueScript: { valueFields: ["availableQuantity"] }, minLimit: 0 }],
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    decimal: true,
    autoFilled: true,
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    decimal: true,
    autoFilled: true,
    valueScript: { operation: "multi", valueFields: ["rate", "totalQuantity"] },
    props: {
      widthFactor: 1,
    },
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
];

export const materialOutwardFields: Field[] = [
  {
    name: "sectionA",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docCompanyName",
                label: "Sender Company Name",
                type: "lookup",
                lookupType: "company",
                lookupCategory: "docCompany",
                allowNull: false,
                props: {
                  width: "17vw",
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docCompanyCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "docLocatorCode",
                    value: "",
                  },
                  {
                    name: "docLocatorName",
                    value: "",
                  },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "docCompanyCode",
                label: "Company",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorCode",
                label: "Locator Code",
                type: "text",
                props: {
                  width: "7vw",
                },
                autoFilled: true,
              },
              {
                name: "docLocatorName",
                label: "Sender Locator Name",
                props: {
                  width: "17vw",
                },
                type: "lookup",
                lookupType: "locator",
                lookupCategory: "docLocator",
                lookupParams: [
                  {
                    key: "projectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "locatorType",
                      values: ["PS", "CS"],
                    },
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "docCompanyCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docLocatorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "docLocatorType",
                  },
                  // {
                  //   additionalFieldName: "company",
                  //   lookupFieldName: "companyCode",
                  //   fieldName: "docCompanyCode",
                  // },
                  // {
                  //   additionalFieldName: "company",
                  //   lookupFieldName: "companyName",
                  //   fieldName: "docCompanyName",
                  // },
                  {
                    lookupFieldName: "description",
                    fieldName: "docStationCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "docStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "docStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "docAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "docAddress2",
                  },
                  // {
                  //   additionalFieldName: "details",
                  //   lookupFieldName: "gstNo",
                  //   fieldName: "docGSTNo",
                  // },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "todayDate",
                    fieldName: "docDate",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateCode",
                    fieldName: "docStateCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "projectCode",
                    value: "",
                  },
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "docOwnerCode",
                    value: "",
                  },
                  {
                    name: "projectType",
                    value: "",
                  },
                  {
                    name: "projectTypeLabel",
                    value: "",
                  },
                  {
                    name: "materialType",
                    value: "F",
                  },
                  {
                    name: "transactionType",
                    value: "",
                  },
                  {
                    name: "senderDocNo",
                    value: null,
                  },
                  {
                    name: "senderDocDate",
                    value: null,
                  },
                  {
                    name: "receiverProjectCode",
                    value: "",
                  },
                  {
                    name: "receiverProjectName",
                    value: "",
                  },
                  {
                    name: "receiverProjectType",
                    value: "",
                  },
                  {
                    name: "receiverOwnerCode",
                    value: "",
                  },
                  {
                    name: "receiverMaterialType",
                    value: "F",
                  },
                  {
                    name: "receiverLocatorName",
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    value: "",
                  },
                  {
                    name: "receiverCompanyName",
                    value: "",
                  },
                  {
                    name: "receiverCompanyCode",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docCompanyCode",
                    },
                  ],
                },
              },
            ],
          },

          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docStationName",
                label: "Sender Station",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "docStateName",
                label: "Sender State",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "materialType",
                label: "Sender Material Type",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "F",
                values: materialTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                props: {
                  width: "12vw",
                },
                linkedFields: [
                  {
                    name: "receiverMaterialType",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "transactionType", values: ["IS"] },
                        resolveValue: true,
                        valueFieldName: "materialType",
                      },
                    ],
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectTypeLabel",
                label: "Sender Project Type",
                type: "lookup",
                lookupType: "project",
                lookupCategory: "outwardProjectType",
                allowUpdate: true,
                props: {
                  width: "11vw",
                },
                lookupParams: [
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                ],
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "projectType",
                    fieldName: "projectType",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                linkedFields: [
                  {
                    name: "transactionType",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                        resolveValue: true,
                        valueFieldName: "transactionType",
                      },
                    ],
                    value: "",
                  },
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "docOwnerCode",
                    value: "",
                  },
                  {
                    name: "projectCode",
                    value: "",
                  },
                  {
                    name: "docGSTNo",
                    value: "",
                  },
                  {
                    name: "receiverProjectName",
                    value: "",
                  },
                  {
                    name: "receiverProjectCode",
                    value: "",
                  },
                  {
                    name: "receiverOwnerCode",
                    value: "",
                  },
                  {
                    name: "receiverGSTNo",
                    value: "",
                  },
                ],
              },
              {
                type: "text",
                label: "Project Type Dummy",
                name: "projectType",
                visibility: {
                  type: "IN",
                  field: "projectType",
                  values: ["dummy"],
                },
                linkedFields: [
                  {
                    name: "receiverProjectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                ],
              },
              {
                name: "docOwnerCode",
                label: "Proj. Owner",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "6vw",
                },
                linkedFields: [
                  {
                    name: "receiverOwnerCode",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                        resolveValue: true,
                        valueFieldName: "docOwnerCode",
                      },
                    ],
                    value: "",
                  },
                ],
              },
              {
                name: "projectCode",
                label: "Proj. Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "6vw",
                },
                linkedFields: [
                  {
                    name: "receiverProjectCode",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                        resolveValue: true,
                        valueFieldName: "projectCode",
                      },
                    ],
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectName",
                label: "Sender Project Name",
                type: "lookup",
                allowUpdate: true,
                lookupType: "project",
                lookupCategory: "outwardProject",
                props: {
                  width: "25vw",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "projectType",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "projectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "virtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "closed",
                    fieldName: "projectClosed",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerCode",
                    fieldName: "docOwnerCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "docGSTNo",
                  },
                ],
                warningMessageConfig: {
                  filter: { type: "IN", field: "projectClosed", values: [true] },
                  message: "Project is closed",
                },
                linkedFields: [
                  {
                    name: "receiverProjectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                  {
                    name: "transactionType",
                    value: "",
                  },
                  {
                    name: "receiverProjectName",
                    value: "",
                  },
                  // {
                  //   name: "receiverProjectName",
                  //   filteredValues: [
                  //     {
                  //       filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                  //       resolveValue: true,
                  //       valueFieldName: "projectName",
                  //     },
                  //   ],
                  //   value: "",
                  // },
                  {
                    name: "receiverLocatorName",
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    value: "",
                  },
                  {
                    name: "receiverCompanyName",
                    value: "",
                  },
                  {
                    name: "receiverCompanyCode",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "projectType",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderDocType",
                label: "Sender Doc Type",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "NA",
                props: {
                  width: "12vw",
                },
                values: senderDocTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                    {
                      type: "AND",
                      filters: [
                        {
                          type: "IN",
                          field: "projectType",
                          values: ["FTTH"],
                        },
                        {
                          type: "IN",
                          field: "docLocatorType",
                          values: ["CS"],
                        },
                      ],
                    },
                  ],
                },
                linkedFields: [
                  { name: "senderDocNo", value: null },
                  { name: "senderDocDate", value: null },
                ],
              },
              {
                name: "docGSTNo",
                label: "Sender GST No",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
                linkedFields: [
                  {
                    name: "senderGSTNo",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                        resolveValue: true,
                        valueFieldName: "docGSTNo",
                      },
                    ],
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderDocNo",
                label: "Sender Doc No",
                type: "lookup",
                lookupType: "child-work-order",
                lookupCategory: "inwardChildWorkOrder",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "docCompanyCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                  ],
                },
                allowUpdate: true,
                defaultValue: "",
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "senderDocDate",
                  },
                ],
                props: {
                  width: "12vw",
                },
                visibility: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["CO"],
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "senderDocType",
                      values: ["CO"],
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "docCompanyCode",
                    },
                  ],
                },
              },
              {
                name: "senderDocNo",
                label: "Sender Doc No",
                type: "text",
                defaultValue: "",
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA", "CO"],
                },
                visibility: {
                  type: "NIN",
                  field: "senderDocType",
                  values: ["CO"],
                },
              },
              {
                name: "senderDocDate",
                label: "Sender Doc Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true, maxDateScript: { operation: "maxDate", valueFields: ["docDate"] } }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA", "CO"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              justify: "right",
            },
            childFields: [
              {
                name: "withinState",
                label: "Within State",
                type: "checkbox",
                defaultValue: true,
                allowUpdate: true,
                linkedFields: [
                  {
                    name: "transactionType",
                    value: "",
                  },
                  {
                    name: "receiverLocatorName",
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    value: "",
                  },
                  {
                    name: "receiverCompanyName",
                    value: "",
                  },
                  {
                    name: "receiverCompanyCode",
                    value: "",
                  },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "sections",
                type: "formSection",
                childFields: [
                  {
                    name: "transactionType",
                    label: "Transaction Type",
                    type: "picklist",
                    defaultValue: "",
                    allowUpdate: true,
                    values: outTransactionTypeValues,
                    linkedFields: [
                      {
                        name: "receiverStationCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "withinState", values: [true] },
                            resolveValue: true,
                            valueFieldName: "docStationCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverStateCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "withinState", values: [true] },
                            resolveValue: true,
                            valueFieldName: "docStateCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverCompanyName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docCompanyName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverCompanyCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docCompanyCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverLocatorName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverLocatorCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverLocatorType",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorType",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverProjectType",
                        resolveValue: true,
                        valueFieldName: "projectType",
                      },
                      {
                        name: "receiverMaterialType",
                        filteredValues: [
                          {
                            filter: { type: "NIN", field: "transactionType", values: ["IS"] },
                            resolveValue: true,
                            valueFieldName: "materialType",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverProjectName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                            resolveValue: true,
                            valueFieldName: "projectName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverProjectCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                            resolveValue: true,
                            valueFieldName: "projectCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverOwnerCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                            resolveValue: true,
                            valueFieldName: "docOwnerCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverGSTNo",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["SL", "PS"] },
                            resolveValue: true,
                            valueFieldName: "docGSTNo",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverAddress1",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docAddress1",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverAddress2",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docAddress2",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverStationName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docStationName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverStateName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docStateName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverStationCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docStationCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverAddress1",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docAddress1",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverAddress2",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docAddress2",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "autoInward",
                        filteredValues: [
                          {
                            filter: {
                              type: "AND",
                              filters: [
                                { type: "IN", field: "autoGP", values: [true] },
                                { type: "IN", field: "transactionType", values: ["IS", "PS", "CS"] },
                              ],
                            },
                            value: true,
                          },
                        ],
                        value: false,
                      },
                      {
                        name: "receiverDocNo",
                        value: "",
                      },
                      {
                        name: "receiverDocDate",
                        value: "",
                      },
                    ],
                    props: {
                      width: "10vw",
                    },
                    disability: {
                      type: "OR",
                      filters: [
                        {
                          type: "EXISTS",
                          field: "details",
                        },
                        {
                          type: "MISSING",
                          field: "docLocatorCode",
                        },
                        {
                          type: "MISSING",
                          field: "projectCode",
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "virtualLocator",
                label: "Sender Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverVirtualLocator",
                label: "Receiver Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "id",
                label: "MOA No",
                type: "text",
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "docDate",
                label: "MOA Date",
                type: "date",
                allowUpdate: true,
                strictDate: true,
                constraints: [
                  {
                    type: "DATE_RANGE",
                    minDateScript: {
                      operation: "maxDate",
                      valueFields: ["senderDocDate", "receiverDocDate"],
                      globalFields: [WORK_START_DATE_FIELD, OUTWARD_CREATION_MIN_DATE],
                    },
                    beforeNow: true,
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "gpFlg",
                label: "Is GP Made",
                type: "checkbox",
                props: {
                  width: "10vw",
                  justify: "center",
                },
                defaultValue: false,
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "gpFlg",
              values: ["1"],
            },
            childFields: [
              {
                name: "gpId",
                label: "Gate Pass Id",
                type: "number",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "gpDate",
                label: "Gate Pass Date",
                type: "date",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverCompanyName",
                label: "Receiver Company Name",
                type: "lookup",
                lookupType: "company",
                allowUpdate: true,
                lookupCategory: "receiverCompany",
                props: {
                  width: "17vw",
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverCompanyCode",
                  },
                ],
                lookupParams: [
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "projectOwnerCode",
                    resolveValue: true,
                    valueFieldName: "docOwnerCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                  {
                    key: "transactionType",
                    resolveValue: true,
                    valueFieldName: "transactionType",
                  },
                  {
                    key: "receiverProjectCode",
                    resolveValue: true,
                    valueFieldName: "receiverProjectCode",
                  },
                  {
                    key: "receiverLocatorCode",
                    resolveValue: true,
                    valueFieldName: "receiverLocatorCode",
                  },
                  {
                    key: "withinState",
                    resolveValue: true,
                    valueFieldName: "withinState",
                  },
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "receiverLocatorName",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                        resolveValue: true,
                        valueFieldName: "docLocatorName",
                      },
                    ],
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                        resolveValue: true,
                        valueFieldName: "docLocatorCode",
                      },
                    ],
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docCompanyCode",
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["IS", "SL", ""],
                    },
                  ],
                },
              },
              {
                name: "receiverCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverLocatorCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "receiverLocatorName",
                label: "Receiver Locator Name",
                type: "lookup",
                lookupType: "locator",
                lookupCategory: "receiverLocator",
                allowUpdate: true,
                props: {
                  width: "17vw",
                },
                lookupParams: [
                  {
                    key: "withinState",
                    resolveValue: true,
                    valueFieldName: "withinState",
                  },
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                  {
                    key: "docProjectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "transactionType",
                    resolveValue: true,
                    valueFieldName: "transactionType",
                  },
                  {
                    key: "receiverProjectCode",
                    resolveValue: true,
                    valueFieldName: "receiverProjectCode",
                  },
                  {
                    key: "receiverProjectType",
                    resolveValue: true,
                    valueFieldName: "receiverProjectType",
                  },
                  {
                    key: "locatorCompany",
                    resolveValue: true,
                    valueFieldName: "receiverCompanyCode",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverLocatorCode",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "receiverStationCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "receiverStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateCode",
                    fieldName: "receiverStateCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "receiverStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "receiverGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "receiverAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "receiverAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "receiverLocatorType",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "receiverCompanyCode",
                    },
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["IS", "SL"],
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverStationName",
                label: "Receiver Station",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "receiverStateName",
                label: "Receiver State/Country",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "receiverMaterialType",
                label: "Receiver Material Type",
                type: "picklist",
                values: materialTypeValues,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["IS"],
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverProjectType",
                label: "Receiver Project Type",
                type: "picklist",
                values: outProjectTypeValues,
                autoFilled: true,
                props: {
                  width: "11vw",
                },
                displayOnly: true,
              },
              {
                name: "receiverOwnerCode",
                label: "Proj. Owner",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "6vw",
                },
              },
              {
                name: "receiverProjectCode",
                label: "Proj. Code",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: "",
                props: {
                  width: "6vw",
                },
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverProjectName",
                label: "Receiver Project Name",
                type: "lookup",
                allowUpdate: true,
                lookupType: "project",
                lookupCategory: "receiverProject",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "receiverProjectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "receiverProjectType",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "receiverStateCode",
                    resolveValue: true,
                    valueFieldName: "receiverStateCode",
                  },
                  {
                    key: "locatorCode",
                    resolveValue: true,
                    valueFieldName: "receiverLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "receiverProjectType",
                  },
                  {
                    key: "locatorType",
                    resolveValue: true,
                    valueFieldName: "receiverLocatorType",
                  },
                  {
                    key: "projectOwnerCode",
                    resolveValue: true,
                    valueFieldName: "docOwnerCode",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverProjectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "receiverVirtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerCode",
                    fieldName: "receiverOwnerCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "receiverGSTNo",
                  },
                ],
                props: {
                  width: "25vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["IS", "CS"],
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverAddress1",
                label: "Address1 ",
                type: "text",
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverAddress2",
                label: "Address2 ",
                type: "text",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverStationName",
                label: "Receiver Station",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "receiverStateName",
                label: "Receiver State/Country",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "PS"],
            },
            childFields: [
              {
                name: "receiverLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "receiverMaterialType",
                label: "Receiver Material Type",
                type: "picklist",
                values: materialTypeValues,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["IS"],
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverDocType",
                label: "Receiver Doc Type",
                type: "picklist",
                defaultValue: "NA",
                allowUpdate: true,
                allowNull: false,
                props: {
                  width: "12vw",
                },
                values: receiverDocTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                    {
                      type: "AND",
                      filters: [
                        {
                          type: "IN",
                          field: "transactionType",
                          values: ["CS"],
                        },
                        {
                          type: "IN",
                          field: "projectType",
                          values: ["FTTH"],
                        },
                      ],
                    },
                  ],
                },
                linkedFields: [
                  { name: "receiverDocNo", value: null },
                  { name: "receiverDocDate", value: null },
                ],
              },
              {
                name: "receiverGSTNo",
                label: "Receiver GST No",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverDocNo",
                label: "Receiver Doc No",
                type: "text",
                allowUpdate: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "NIN",
                  field: "receiverDocType",
                  values: ["PO", "LO", "MR"],
                },
                visibility: {
                  type: "NIN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
              },
              {
                name: "receiverDocNo",
                label: "Receiver Doc No",
                type: "lookup",
                lookupType: "child-work-order",
                lookupCategory: "inwardChildWorkOrder",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "receiverCompanyCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "receiverProjectCode",
                    },
                  ],
                },
                allowUpdate: true,
                defaultValue: "",
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "receiverDocDate",
                  },
                ],
                props: {
                  width: "12vw",
                },
                visibility: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "receiverDocType",
                      values: ["CO"],
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
              {
                name: "receiverDocDate",
                label: "Receiver Doc Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true, maxDateScript: { operation: "maxDate", valueFields: ["docDate"] } }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["CO", "NA"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "details",
    label: "Details",
    allowUpdate: true,
    allowNull: true,
    type: "formTable",
    tableFields: materialOutwardDetailFields,
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "projectCode",
        },
        {
          type: "MISSING",
          field: "receiverLocatorCode",
        },
        {
          type: "MISSING",
          field: "materialType",
        },
        {
          type: "MISSING",
          field: "transactionType",
        },
      ],
    },
    props: {
      width: "82vw",
    },
  },
  {
    name: "sectionB",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
      justify: "start",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "transportMode",
                label: "Transport Mode",
                type: "picklist",
                defaultValue: "SF",
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                values: [
                  { value: "TP", label: "Transporter" },
                  { value: "CR", label: "Courier" },
                  { value: "SF", label: "Self" },
                ],
                disability: {
                  field: "autoGP",
                  type: "IN",
                  values: [false],
                },
              },
              {
                name: "vehicleNo",
                label: "Vehicle Number",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  field: "autoGP",
                  type: "IN",
                  values: [false],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "vendorName",
                label: "Vendor Name",
                type: "lookup",
                lookupType: "company",
                defaultValue: null,
                allowUpdate: true,
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "vendorCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "vendorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "ownerFlg",
                      values: false,
                    },
                    {
                      type: "EQUALS",
                      field: "serviceFlg",
                      values: true,
                    },
                  ],
                },
                props: {
                  width: "17vw",
                },
                disability: {
                  field: "transportMode",
                  type: "NIN",
                  values: ["TP", "CR"],
                },
              },
              {
                name: "vendorCode",
                label: "Code",
                type: "text",
                defaultValue: null,
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "consignmentNote",
                label: "Consignment Note",
                type: "text",
                allowNull: true,
                defaultValue: null,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  field: "transportMode",
                  type: "NIN",
                  values: ["TP", "CR"],
                },
              },
              {
                name: "ewayBillNo",
                label: "Eway Bill No",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  field: "autoGP",
                  type: "IN",
                  values: [false],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "21vw",
              justify: "center",
            },
            childFields: [
              {
                name: "cancelled",
                label: "Cancelled",
                type: "checkbox",
                props: {
                  width: "21vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disabledForCreation: true,
                disability: {
                  type: "IN",
                  field: "submit",
                  values: [true],
                },
                linkedFields: [
                  {
                    name: "autoGP",
                    value: false,
                  },
                ],
              },
              {
                name: "submit",
                label: "Submit",
                type: "checkbox",
                props: {
                  width: "21vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "IN",
                  field: "cancelled",
                  values: [true],
                },
                linkedFields: [
                  {
                    name: "autoGP",
                    value: false,
                  },
                  {
                    name: "transportMode",
                    value: "SF",
                  },
                  {
                    name: "vehicleNo",
                    value: null,
                  },
                  {
                    name: "vendorName",
                    value: null,
                  },
                  {
                    name: "vendorCode",
                    value: null,
                  },
                  {
                    name: "consignmentNote",
                    value: null,
                  },
                  {
                    name: "ewayBillNo",
                    value: null,
                  },
                  {
                    name: "totalPkgs",
                    value: 0,
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "totalPkgs",
                label: "Total Packages",
                type: "number",
                defaultValue: 0,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
                disability: {
                  field: "autoGP",
                  type: "IN",
                  values: [false],
                },
              },
              {
                name: "totalValue",
                label: "Total Value",
                type: "text",
                defaultValue: "0",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "autoGP",
                label: "Auto GP",
                type: "checkbox",
                defaultValue: false,
                allowUpdate: true,
                props: {
                  width: "10vw",
                  justify: "left",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "submit",
                      values: [false],
                    },
                    {
                      type: "IN",
                      field: "gpFlg",
                      values: [true],
                    },
                  ],
                },
                linkedFields: [
                  {
                    name: "transportMode",
                    value: "SF",
                  },
                  {
                    name: "vehicleNo",
                    value: null,
                  },
                  {
                    name: "vendorName",
                    value: null,
                  },
                  {
                    name: "vendorCode",
                    value: null,
                  },
                  {
                    name: "consignmentNote",
                    value: null,
                  },
                  {
                    name: "ewayBillNo",
                    value: null,
                  },
                  {
                    name: "totalPkgs",
                    value: 0,
                  },
                  {
                    name: "autoInward",
                    filteredValues: [
                      {
                        filter: {
                          type: "AND",
                          filters: [
                            { type: "IN", field: "autoGP", values: [true] },
                            { type: "IN", field: "transactionType", values: ["IS", "PS", "CS"] },
                          ],
                        },
                        value: true,
                      },
                    ],
                    value: false,
                  },
                ],
              },
              {
                name: "autoInward",
                label: "Auto Inward",
                type: "checkbox",
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "autoGP",
                      values: [false],
                    },
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["PS", "CS"],
                    },
                  ],
                },
                props: {
                  width: "10vw",
                  justify: "right",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "remarks",
                label: "Remarks",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "mirId",
                label: "MIR No",
                type: "text",
                defaultValue: null,
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "7vw",
                  justify: "right",
                },
                // visibility: {
                //   type: "AND",
                //   filters: [
                //     {
                //       type: "IN",
                //       field: "autoGP",
                //       values: [true],
                //     },
                //     {
                //       type: "IN",
                //       field: "transactionType",
                //       values: ["IS", "CS", "PS"],
                //     },
                //   ],
                // },
              },
            ],
          },
          {
            name: "fileInfos",
            label: "File Infos",
            type: "attachment",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
            },
          },
        ],
      },
    ],
  },
  // {
  //   name: "sectionB",
  //   type: "formSection",
  //   alignment: "row",
  //   props: {
  //     width: "25vw",
  //     spacing: "2vw",
  //   },
  //   childFields: [
  //     {
  //       name: "section1",
  //       type: "formSection",
  //       alignment: "column",
  //       props: {
  //         width: "25vw",
  //         spacing: "0.75vw",
  //         justify: "top",
  //       },
  //       childFields: [],
  //     },
  //   ],
  // },
];

export const materialOutwardColumns = [
  {
    name: "id",
    label: "MOA No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorType",
    label: "Type",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "transactionType",
    label: "Transaction",
    allowNull: true,
    type: "text",
    format: (value) => {
      const transactionType = outTransactionTypeValues.find((item) => item.value === value);
      return transactionType ? transactionType.label : "Unknown";
    },
  },
  // {
  //   name: "materialType",
  //   label: "Mat Type",
  //   allowNull: true,
  //   type: "text",
  //   format: (value) => {
  //     const materialType = materialTypeValues.find((item) => item.value === value);
  //     return materialType ? materialType.label : "Unknown";
  //   },
  // },
  {
    name: "receiverCompanyName",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const materialOutwardSearchColumns = [
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    defaultValue: null,
  },
  {
    name: "materialOutwardMst.docLocatorCode",
    label: "Out Locator",
    type: "text",
    defaultValue: null,
  },
  {
    name: "docLocator.locatorName",
    label: "Locator Name",
    foreignEntityType: "docLocator",
    type: "text",
  },
  {
    name: "materialOutwardMst.projectCode",
    label: "Project",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "picklist",
    defaultValue: null,
    values: outTransactionTypeValues,
  },
  // {
  //   name: "materialType",
  //   label: "Mat Type",
  //   type: "picklist",
  //   defaultValue: null,
  //   values: materialTypeValues,
  // },
  {
    name: "companyName",
    label: "Recv Company",
    type: "text",
    foreignEntityType: "receiverCompany",
    defaultValue: null,
  },
  {
    name: "receiverLocator.locatorName",
    label: "Recv Locator",
    type: "text",
    foreignEntityType: "receiverLocator",
    defaultValue: null,
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "submit",
    label: "Submit",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];

export const materialOutwardExcelColumns = [
  {
    name: "id",
    label: "MOA No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Out Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Out Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "Out State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, outProjectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Out Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => outTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverLocatorCode",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator Name",
    type: "text",
  },
  {
    name: "receiverAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "receiverAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "receiverStationName",
    label: "Receiver Station",
    type: "text",
  },
  {
    name: "receiverStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "receiverCompanyCode",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpId",
    label: "GP No",
    type: "number",
  },
  {
    name: "gpDate",
    label: "GP Date",
    type: "date",
    format: (value) => formatDate(value),
  },
];

export const materialOutwardDetailExcelColumns = [
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Out Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "Out State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, outProjectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Out Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => outTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverLocatorCode",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator Name",
    type: "text",
  },
  {
    name: "receiverAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "receiverAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "receiverStationName",
    label: "Receiver Station",
    type: "text",
  },
  {
    name: "receiverStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "receiverCompanyCode",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "mirLotId",
    label: "MIR Lot",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "partSerialNo",
    label: "Part Serial",
    type: "text",
  },
  {
    name: "batchNo",
    label: "Batch No",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Out Quantity",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];
