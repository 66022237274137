import { DefaultHandler } from "./DefaultHandler";
import { User } from "../../components/users/UserTypes";
class MyProfileHandler extends DefaultHandler {
  override isUnSubmitRequired = () => false;
  override canDelete = (recordType, currentUser, user: User) => false;
  override getEntityType(): string {
    return "my-profile";
  }
}
const userHandler = new MyProfileHandler();
export default userHandler;
