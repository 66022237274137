import { Field, PicklistValue } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { ConfigKey } from "../config/configHolder";

export const allProjectTypeValues: PicklistValue[] = [
  {
    value: "FF",
    label: "FF Work",
    visibility: {
      type: "AND",
      filters: [{ type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] }],
    },
  },
  {
    value: "FTTH",
    label: "FTTH Work",
    visibility: {
      type: "AND",
      filters: [{ type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] }],
    },
  },
  {
    value: "NFIM",
    label: "NFIM Work",
  },
  {
    value: "REG",
    label: "Regular Work",
    visibility: {
      type: "AND",
      filters: [{ type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["OTH"] }],
    },
  },
];

export const projectTypeValues: PicklistValue[] = [
  {
    value: "FF",
    label: "FF Work",
    visibility: {
      type: "AND",
      filters: [
        { type: "IN", field: "compBucket", values: ["BAL"] },
        { type: "IN", field: "ownerFlg", values: [true] },
        { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] },
      ],
    },
  },
  {
    value: "FTTH",
    label: "FTTH Work",
    visibility: {
      type: "AND",
      filters: [
        { type: "IN", field: "compBucket", values: ["BAL"] },
        { type: "IN", field: "ownerFlg", values: [true] },
        { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] },
      ],
    },
  },
  {
    value: "NFIM",
    label: "NFIM Work",
    visibility: {
      type: "AND",
      filters: [
        { type: "IN", field: "compBucket", values: ["OTH"] },
        { type: "IN", field: "partnerFlg", values: [true] },
      ],
    },
  },
  {
    value: "REG",
    label: "Regular Work",
    visibility: {
      type: "AND",
      filters: [
        { type: "IN", field: "compBucket", values: ["OTH"] },
        { type: "IN", field: "ownerFlg", values: [true] },
        { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["OTH"] },
      ],
    },
  },
];

export const inProjectTypeValues: PicklistValue[] = [
  {
    value: "FF",
    label: "FF Work",
    visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] },
  },
  {
    value: "FTTH",
    label: "FTTH Work",
    visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] },
  },
  {
    value: "REG",
    label: "Regular Work",
    visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["OTH"] },
  },
  {
    value: "NFIM",
    label: "NFIM Work",
    visibility: {
      type: "AND",
      filters: [{ type: "IN", field: "docLocatorType", values: ["PS"] }],
    },
  },
];

export const outProjectTypeValues: PicklistValue[] = [
  {
    value: "FF",
    label: "FF Work",
    visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] },
  },
  {
    value: "FTTH",
    label: "FTTH Work",
    visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] },
  },
  {
    value: "REG",
    label: "Regular Work",
    visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["OTH"] },
  },
  {
    value: "NFIM",
    label: "NFIM Work",
  },
];

export const consuProjectTypeValues: PicklistValue[] = [
  { value: "FF", label: "FF Work", visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] } },
  { value: "FTTH", label: "FTTH Work", visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["BAL"] } },
  { value: "REG", label: "Regular Work", visibility: { type: "CONTAINS", field: ConfigKey.ENABLED_COMP_BUCKETS, values: ["OTH"] } },
];

export const projectFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "section1",
        type: "formSection",
        childFields: [
          {
            name: "companyName",
            label: "Project Owner Company",
            type: "lookup",
            allowUpdate: false,
            lookupType: "company",
            lookupCategory: "projectOwner",
            lookupParams: [
              {
                key: "projectType",
                resolveValue: true,
                valueFieldName: "projectType",
              },
            ],
            dependentFields: [
              {
                lookupFieldName: "id",
                fieldName: "ownerCode",
              },
              {
                additionalFieldName: "details",
                lookupFieldName: "gstNo",
                fieldName: "gstNo",
              },
              {
                additionalFieldName: "details",
                lookupFieldName: "compBucket",
                fieldName: "compBucket",
              },
              {
                additionalFieldName: "details",
                lookupFieldName: "ownerFlg",
                fieldName: "ownerFlg",
              },
              {
                additionalFieldName: "details",
                lookupFieldName: "partnerFlg",
                fieldName: "partnerFlg",
              },
            ],
            props: {
              width: "17vw",
            },
            linkedFields: [
              {
                name: "projectType",
                value: "",
              },
              {
                name: "virtualLocator",
                value: "",
              },
              {
                name: "routeLength",
                value: "",
              },
              {
                name: "gstNo",
                value: "",
              },
            ],
          },
        ],
      },
      {
        name: "ownerCode",
        label: "Owner Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectCode",
        label: "Project",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        values: projectTypeValues,
        props: {
          width: "17vw",
        },
        linkedFields: [
          {
            name: "virtualLocator",
            value: "",
          },
          {
            name: "routeLength",
            value: "",
          },
        ],
        disability: {
          type: "AND",
          filters: [{ type: "MISSING", field: "ownerCode" }],
        },
      },
    ],
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "ownerValidStates",
        defaultValue: null,
        allowUpdate: false,
        emptyResponseMessage: "No Company GST Entry For Owner",
        lookupParams: [
          {
            key: "companyCode",
            resolveValue: true,
            valueFieldName: "ownerCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
        ],
        linkedFields: [
          {
            name: "gstNo",
            value: null,
          },
        ],
        disability: {
          type: "MISSING",
          field: "ownerCode",
        },
        props: {
          width: "17vw",
        },
      },
      {
        name: "stateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "gstNo",
        label: "GST Number",
        type: "lookup",
        lookupType: "companyGst",
        lookupCategory: "locatorCompany",
        defaultValue: null,
        props: {
          width: "17vw",
        },
        disability: {
          type: "OR",
          filters: [
            { type: "MISSING", field: "companyName" },
            { type: "MISSING", field: "stateName" },
          ],
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "ownerCode",
            },
            {
              type: "EQUALS",
              field: "gstCode",
              resolveValue: true,
              valueFieldName: "gstCode",
            },
          ],
        },
      },
      {
        name: "gstCode",
        label: "GST Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "IN", field: "compBucket", values: ["BAL"] },
    childFields: [
      {
        name: "virtualLocator",
        label: "Virtual Locator",
        type: "text",
        defaultValue: "",
        allowUpdate: false,
        props: {
          width: "17vw",
        },
      },
      {
        name: "routeLength",
        label: "Route (Mtr)",
        type: "number",
        allowNull: false,
        allowUpdate: true,
        defaultValue: "",
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "NIN", field: "compBucket", values: ["BAL"] },
    childFields: [
      {
        name: "virtualLocator",
        label: "Inventory Bucket Identifier",
        type: "text",
        defaultValue: "",
        allowUpdate: false,
        props: {
          width: "25vw",
        },
        disability: {
          type: "IN",
          field: "projectType",
          values: ["NFIM", ""],
        },
      },
    ],
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    props: {
      width: "25vw",
    },
  },
  {
    name: "oprojectName",
    label: "Original Project Name",
    type: "text",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        allowUpdate: false,
        autoFilled: true,
        defaultValue: false,
      },
    ],
  },
];

export const projectColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Owner Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Owner Company",
    type: "text",
  },
  // {
  //   name: "gstNo",
  //   label: "GST No",
  //   type: "text",
  // },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const projectExcelColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Owner Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Owner Name",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST No",
    type: "text",
  },
  {
    name: "routeLength",
    label: "Route Length (Mtr)",
    type: "number",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const projectSearchColumns = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "picklist",
    defaultValue: "",
    values: projectTypeValues,
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Owner Code",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
