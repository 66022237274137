import { formatDate, SYSTEM_START_DATE_FIELD } from "../services/dateUtils";
import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { projectTypeValues } from "./project";
import { materialTypeValues } from "./materialInward";
import { cwoTypeValues } from "./childWorkOrder";
import { jobTypeValues } from "./job";
import { workTypeValues } from "./work";

export const itemCategoryValues = [
  { value: "FIM", label: "Free Issue Material" },
  { value: "NFIM", label: "Non Free Issue Mat" },
];

export const referenceDocTypeValues = [{ value: "NA", label: "N/A" }];

export const cbSheetDetailFields: Field[] = [
  {
    name: "sno",
    label: "SNo.",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.5,
    },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "lookup",
    lookupType: "item",
    lookupCategory: "cbSheetItem",
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
      },
      {
        key: "materialType",
        resolveValue: true,
        valueFieldName: "docMaterialType",
      },
      {
        key: "projectCode",
        resolveValue: true,
        valueFieldName: "docProjectCode",
      },
      {
        key: "cbSheetDocDate",
        resolveValue: true,
        valueFieldName: "docDate",
      },
      {
        key: "projectType",
        resolveValue: true,
        valueFieldName: "consuProjectType",
      },
      {
        key: "ownerCode",
        resolveValue: true,
        valueFieldName: "consuOwnerCode",
      },
      {
        key: "usedItemProjectCodes",
        resolveValue: true,
        valueFieldName: "itemProjectCodes",
      },
    ],
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "itemCode",
        fieldName: "itemCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "itemCustCode",
        fieldName: "itemCustCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "itemId",
        fieldName: "itemId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "uom",
        fieldName: "uom",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "decimalFlg",
        fieldName: "decimalFlg",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "availableQuantity",
        fieldName: "availableQuantity",
      },
    ],
    props: {
      widthFactor: 3,
    },
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 1.3,
    },
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "projectVirtualLocator",
    label: "Project Virtual Locator",
    type: "lookup",
    defaultValueFieldName: "docProjectName",
    allowUpdate: false,
    allowNull: true,
    lookupType: "project",
    lookupCategory: "outwardProjectVirtual",
    props: {
      widthFactor: 3,
    },
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "projectType",
          resolveValue: true,
          valueFieldName: "projectType",
        },
      ],
    },
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
      },
      {
        key: "projectType",
        resolveValue: true,
        valueFieldName: "projectType",
      },
      {
        key: "ownerCode",
        resolveValue: true,
        valueFieldName: "consuOwnerCode",
      },
      {
        key: "itemId",
        resolveValue: true,
        valueFieldName: "itemId",
      },
      {
        key: "usedItemProjectCodes",
        resolveValue: true,
        valueFieldName: "itemProjectCodes",
      },
    ],
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "projectCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "totalAvailableQuantity",
        fieldName: "availableQuantity",
      },
    ],
    disability: {
      type: "OR",
      filters: [
        {
          type: "NIN",
          field: "multiProjectFlg",
          values: [true],
        },
      ],
    },
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
    defaultValueFieldName: "docProjectCode",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    allowNull: true,
    autoFilled: true,
    hiddenForView: true,
  },
  {
    name: "totalQuantity",
    label: "Used Qty",
    type: "number",
    defaultValue: 0,
    decimalFlg: true,
    constraints: [{ type: "RANGE", maxValueScript: { valueFields: ["availableQuantity"] }, minLimit: 0 }],
    disability: {
      type: "OR",
      filters: [
        {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "multiProjectFlg",
              values: [true],
            },
            {
              type: "MISSING",
              field: "projectVirtualLocator",
            },
          ],
        },
      ],
    },
  },
  // {
  //   name: "rate",
  //   label: "Rate",
  //   type: "number",
  //   decimal: true,
  //   autoFilled: true,
  //   allowNull: true,
  // },
  {
    name: "totalAmount",
    label: "Used Amount",
    type: "number",
    decimal: true,
    autoFilled: true,
    valueScript: { operation: "multi", valueFields: ["rate", "totalQuantity"] },
    props: {
      widthFactor: 1,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "sumTotalQuantity",
    label: "Item Qty",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.7,
    },
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.7,
    },
  },
];

export const cbSheetFields: Field[] = [
  {
    name: "sectionA",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docCompanyName",
                label: "Contractor Company Name",
                type: "lookup",
                lookupType: "company",
                lookupCategory: "consumptionContractor",
                allowNull: false,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "contractorFlg",
                      values: [true],
                    },
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "docCompanyCode",
                    },
                    {
                      type: "EQUALS",
                      field: "active",
                      values: true,
                    },
                  ],
                },
                props: {
                  width: "17vw",
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docCompanyCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "docLocatorCode",
                    value: "",
                  },
                  {
                    name: "docLocatorName",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    // {
                    //   type: "EXISTS",
                    //   field: "docLocatorCode",
                    // },
                  ],
                },
              },
              {
                name: "docCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorCode",
                label: "Code",
                type: "text",
                props: {
                  width: "7vw",
                },
                autoFilled: true,
              },
              {
                name: "docLocatorName",
                label: "Contractor Locator Name",
                props: {
                  width: "17vw",
                },
                type: "lookup",
                lookupType: "locator",
                lookupCategory: "consumptionLocator",
                lookupParams: [
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docLocatorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "docLocatorType",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "docStationCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "stationName",
                    fieldName: "docStationName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "stateName",
                    fieldName: "docStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "stateCode",
                    fieldName: "stateCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "todayDate",
                    fieldName: "docDate",
                  },
                ],
                linkedFields: [
                  {
                    name: "docProjectCode",
                    value: "",
                  },
                  {
                    name: "docProjectName",
                    value: "",
                  },
                  {
                    name: "docOwnerCode",
                    value: "",
                  },
                  {
                    name: "docOwnerName",
                    value: "",
                  },
                  {
                    name: "projectType",
                    value: "",
                  },
                  {
                    name: "docMaterialType",
                    value: "F",
                  },
                  {
                    name: "consuProjectCode",
                    value: "",
                  },
                  {
                    name: "consuProjectName",
                    value: "",
                  },
                  {
                    name: "consuProjectTypeLabel",
                    value: "",
                  },
                  {
                    name: "consuProjectType",
                    value: "",
                  },
                  {
                    name: "consuOwnerCode",
                    value: "",
                  },
                  {
                    name: "consuOwnerName",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docCompanyCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docStationName",
                label: "Station",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "docStateName",
                label: "State",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docMaterialType",
                label: "Material Type",
                type: "picklist",
                defaultValue: "F",
                values: materialTypeValues,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
              },
              {
                name: "itemCategory",
                label: "Item Category",
                type: "picklist",
                defaultValue: "FIM",
                values: itemCategoryValues,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                linkedFields: [
                  {
                    name: "docOwnerCode",
                    value: "",
                  },
                  {
                    name: "docOwnerName",
                    value: "",
                  },
                  {
                    name: "docProjectCode",
                    value: "",
                  },
                  {
                    name: "docProjectName",
                    value: "",
                  },
                  {
                    name: "projectType",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "itemCategory", values: ["NFIM"] },
                        resolveValue: true,
                        valueFieldName: "itemCategory",
                      },
                    ],
                    value: "",
                  },
                  {
                    name: "consuProjectType",
                    value: "",
                  },
                  {
                    name: "multiProjectFlg",
                    value: false,
                  },
                  {
                    name: "consuProjectName",
                    value: "",
                  },
                  {
                    name: "consuProjectCode",
                    value: "",
                  },
                  {
                    name: "consuOwnerCode",
                    value: "",
                  },
                  {
                    name: "consuOwnerName",
                    value: "",
                  },
                  {
                    name: "consuVirtualLocator",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docProjectName",
                label: "Source Project Name",
                type: "lookup",
                allowUpdate: true,
                allowNull: true,
                lookupType: "project",
                lookupCategory: "outwardProject",
                props: {
                  width: "17vw",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "projectType",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docProjectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "virtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "closed",
                    fieldName: "projectClosed",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerCode",
                    fieldName: "docOwnerCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerName",
                    fieldName: "docOwnerName",
                  },
                ],
                warningMessageConfig: {
                  filter: { type: "IN", field: "projectClosed", values: [true] },
                  message: "Project is closed",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                    {
                      type: "IN",
                      field: "itemCategory",
                      values: ["FIM"],
                    },
                  ],
                },
              },
              {
                name: "docProjectCode",
                label: "Project Code",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "itemCategory",
              values: ["dummy"],
            },
            childFields: [
              {
                name: "projectType",
                label: "Project Type",
                type: "picklist",
                allowUpdate: true,
                autoFilled: true,
                values: projectTypeValues,
                props: {
                  width: "9vw",
                },
              },
              {
                name: "docOwnerCode",
                label: "Material Owner",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "virtualLocator",
                label: "Virtual Locator",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "docOwnerName",
                label: "Owner Name",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "consuProjectTypeLabel",
                label: "Consume Project Type",
                type: "lookup",
                lookupType: "project",
                lookupCategory: "consumptionProjectType",
                defaultValue: "",
                props: {
                  width: "10vw",
                },
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "projectType",
                    fieldName: "consuProjectType",
                  },
                ],
                lookupParams: [
                  {
                    key: "locatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "stateCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "consuProjectName",
                    value: "",
                  },
                  {
                    name: "consuProjectCode",
                    value: "",
                  },
                  {
                    name: "consuOwnerCode",
                    value: "",
                  },
                  {
                    name: "consuOwnerName",
                    value: "",
                  },
                  {
                    name: "jobId",
                    value: "",
                  },
                  {
                    name: "cwoId",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                    {
                      type: "AND",
                      filters: [
                        {
                          type: "IN",
                          field: "itemCategory",
                          values: ["NFIM"],
                        },
                        {
                          type: "MISSING",
                          field: "docProjectCode",
                        },
                      ],
                    },
                  ],
                },
              },
              {
                name: "consuProjectType",
                label: "Consume Project Type",
                type: "text",
                visibility: {
                  type: "IN",
                  field: "consuProjectType",
                  values: ["aa"],
                },
                linkedFields: [
                  {
                    name: "multiProjectFlg",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "consuProjectType", values: ["FF", "FTTH"] },
                        value: false,
                      },
                    ],
                    value: true,
                  },
                ],
              },
              {
                name: "consuProjectCode",
                label: "Consume Project Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "10vw",
                },
                linkedFields: [
                  {
                    name: "docProjectCode",
                    filteredValues: [
                      {
                        filter: {
                          type: "AND",
                          filters: [
                            { type: "IN", field: "multiProjectFlg", values: [false] },
                            { type: "IN", field: "itemCategory", values: ["FIM"] },
                          ],
                        },
                        resolveValue: true,
                        valueFieldName: "consuProjectCode",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "docProjectCode",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "multiProjectFlg",
                label: "From Multi Project",
                type: "checkbox",
                props: {
                  justify: "right",
                },
                defaultValue: false,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "consuProjectType",
                    },
                    {
                      type: "IN",
                      field: "itemCategory",
                      values: ["NFIM",""],
                    },
                  ],
                },
                linkedFields: [
                  {
                    name: "consuProjectName",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "multiProjectFlg", values: [false] },
                        value: "",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "consuProjectName",
                  },
                  {
                    name: "consuProjectCode",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "multiProjectFlg", values: [false] },
                        value: "",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "consuProjectCode",
                  },
                  {
                    name: "consuVirtualLocator",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "multiProjectFlg", values: [false] },
                        value: "",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "consuVirtualLocator",
                  },
                  {
                    name: "docProjectName",
                    value: "",
                  },
                  {
                    name: "docProjectCode",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "consuProjectName",
                label: "Consume Project Name",
                type: "lookup",
                lookupType: "project",
                lookupCategory: "consumptionProject",
                props: {
                  width: "21vw",
                },
                lookupParams: [
                  {
                    key: "locatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "consuProjectType",
                  },
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "stateCode",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "consuProjectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "consuVirtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "routeLength",
                    fieldName: "routeLength",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "closed",
                    fieldName: "projectClosed",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerCode",
                    fieldName: "consuOwnerCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerName",
                    fieldName: "consuOwnerName",
                  },
                ],
                warningMessageConfig: {
                  filter: { type: "IN", field: "projectClosed", values: [true] },
                  message: "Project is closed",
                },
                linkedFields: [
                  {
                    name: "docProjectName",
                    filteredValues: [
                      {
                        filter: { type: "IN", field: "multiProjectFlg", values: [false] },
                        resolveValue: true,
                        valueFieldName: "consuProjectName",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "docProjectName",
                  },
                  {
                    name: "jobId",
                    value: "",
                  },
                  {
                    name: "cwoId",
                    value: "",
                  },
                  {
                    name: "partnerInvNo",
                    value: "",
                  },
                  {
                    name: "partnerInvDate",
                    value: "",
                  },
                  {
                    name: "contractorInvNo",
                    value: "",
                  },
                  {
                    name: "contractorInvDate",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                    {
                      type: "MISSING",
                      field: "consuProjectType",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "consuVirtualLocator",
                label: "Consume Virtual Locator",
                type: "text",
                autoFilled: true,
                allowUpdate: false,
                props: {
                  width: "21vw",
                },
                linkedFields: [
                  {
                    name: "virtualLocator",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "itemCategory", values: ["NFIM"] },
                        resolveValue: true,
                        valueFieldName: "consuVirtualLocator",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "virtualLocator",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "itemCategory",
              values: ["dummy"],
            },
            childFields: [
              {
                name: "consuOwnerCode",
                label: "Consume Owner",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
                linkedFields: [
                  {
                    name: "docOwnerCode",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "itemCategory", values: ["NFIM"] },
                        resolveValue: true,
                        valueFieldName: "consuOwnerCode",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "docOwnerCode",
                  },
                ],
              },
              {
                name: "consuOwnerName",
                label: "Consume Owner Name",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "21vw",
                },
                linkedFields: [
                  {
                    name: "docOwnerName",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "itemCategory", values: ["NFIM"] },
                        resolveValue: true,
                        valueFieldName: "consuOwnerName",
                      },
                    ],
                    resolveValue: true,
                    valueFieldName: "docOwnerName",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "id",
                label: "CBS No",
                type: "text",
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "docDate",
                label: "CBS Date",
                type: "date",
                strictDate: true,
                displayOnly: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        visibility: {
          type: "NIN",
          field: "consuProjectType",
          values: ["FTTH", "REG"],
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "jobId",
                label: "Job Id",
                type: "lookup",
                lookupType: "job",
                allowUpdate: false,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "stateCode",
                      resolveValue: true,
                      valueFieldName: "stateCode",
                    },
                    {
                      type: "IN",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "consuProjectCode",
                    },
                    {
                      type: "IN",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "siteId",
                    fieldName: "siteId",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "jobType",
                    fieldName: "jobType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "jobType",
                    fieldName: "taskType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "poNo",
                    fieldName: "poNo",
                  },
                ],
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    { type: "EXISTS", field: "details" },
                    { type: "MISSING", field: "docLocatorCode" },
                    { type: "MISSING", field: "stateCode" },
                    { type: "MISSING", field: "consuProjectCode" },
                    // { type: "MISSING", field: "projectCode" },
                  ],
                },
              },
              {
                name: "jobType",
                label: "Job Type",
                type: "picklist",
                autoFilled: true,
                values: jobTypeValues,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "siteId",
                label: "Site Id",
                type: "text",
                displayOnly: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "poNo",
                label: "PO number",
                type: "number",
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "routeLength",
                label: "Route Length",
                type: "number",
                displayOnly: true,
                allowNull: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "partnerInvNo",
                label: "Partner Inv No.",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "partnerInvDate",
                label: "Invoice Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "contractorInvNo",
                label: "Contractor Inv No.",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "contractorInvDate",
                label: "Invoice Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        visibility: {
          type: "IN",
          field: "consuProjectType",
          values: ["FTTH"],
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "cwoId",
                label: "CWO Id",
                type: "lookup",
                lookupType: "child-work-order",
                allowUpdate: false,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "stateCode",
                      resolveValue: true,
                      valueFieldName: "stateCode",
                    },
                    {
                      type: "IN",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "consuProjectCode",
                    },
                    {
                      type: "IN",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "cwoNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "cwoDate",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoType",
                    fieldName: "cwoType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoType",
                    fieldName: "taskType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "workArea",
                    fieldName: "workArea",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "homePass",
                    fieldName: "homePass",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "routeLength",
                    fieldName: "routeLength",
                  },
                ],
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    { type: "EXISTS", field: "details" },
                    { type: "MISSING", field: "docLocatorCode" },
                    { type: "MISSING", field: "stateCode" },
                    { type: "MISSING", field: "consuProjectCode" },
                    // { type: "MISSING", field: "projectCode" },
                  ],
                },
              },
              {
                name: "cwoDate",
                label: "CWO Date",
                type: "date",
                displayOnly: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "cwoNo",
            label: "CWO No",
            type: "text",
            displayOnly: true,
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "homePass",
                label: "Home Pass",
                type: "number",
                displayOnly: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
              {
                name: "routeLength",
                label: "Route Length",
                type: "number",
                displayOnly: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "partnerInvNo",
                label: "Partner Inv No.",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "partnerInvDate",
                label: "Invoice Date",
                type: "date",
                constraints: [
                  {
                    type: "DATE_RANGE",
                    minDateScript: {
                      operation: "minDate",
                      globalFields: [SYSTEM_START_DATE_FIELD],
                    },
                    beforeNow: true,
                  },
                ],
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "contractorInvNo",
                label: "Contractor Inv No.",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "contractorInvDate",
                label: "Invoice Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        visibility: {
          type: "IN",
          field: "consuProjectType",
          values: ["REG"],
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "workId",
                label: "Work Id",
                type: "lookup",
                lookupType: "work",
                allowUpdate: false,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "stateCode",
                      resolveValue: true,
                      valueFieldName: "stateCode",
                    },
                    {
                      type: "IN",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "consuProjectCode",
                    },
                    {
                      type: "IN",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "siteId",
                    fieldName: "siteId",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "workType",
                    fieldName: "workType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "workType",
                    fieldName: "taskType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "workDate",
                    fieldName: "workDate",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "poNo",
                    fieldName: "poNo",
                  },
                ],
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    { type: "EXISTS", field: "details" },
                    { type: "MISSING", field: "docLocatorCode" },
                    { type: "MISSING", field: "stateCode" },
                    { type: "MISSING", field: "consuProjectCode" },
                    // { type: "MISSING", field: "projectCode" },
                  ],
                },
              },
              {
                name: "workType",
                label: "Work Type",
                type: "picklist",
                autoFilled: true,
                values: workTypeValues,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "siteId",
                label: "Site Id",
                type: "text",
                displayOnly: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "poNo",
                label: "PO number",
                type: "text",
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "workDate",
                label: "Work Date",
                type: "date",
                displayOnly: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "partnerInvNo",
                label: "Partner Inv No.",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "partnerInvDate",
                label: "Invoice Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "contractorInvNo",
                label: "Contractor Inv No.",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "contractorInvDate",
                label: "Invoice Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "details",
    label: "Details",
    allowUpdate: true,
    allowNull: true,
    type: "formTable",
    tableFields: cbSheetDetailFields,
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "consuProjectCode",
        },
        {
          type: "MISSING",
          field: "docLocatorName",
        },
        {
          type: "MISSING",
          field: "docMaterialType",
        },
        {
          type: "MISSING",
          field: "taskType",
        },
      ],
    },
    props: {
      width: "82vw",
    },
  },
  {
    name: "sectionB",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
      justify: "start",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "remarks",
            label: "Remarks",
            type: "text",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
            },
          },
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "25vw",
              justify: "start",
            },
            childFields: [
              {
                name: "closureId",
                label: "Closure No",
                type: "text",
                allowNull: true,
                allowUpdate: false,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "closureDate",
                label: "Closure Date",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              justify: "right",
            },
            childFields: [
              {
                name: "closed",
                label: "Closed Work",
                type: "checkbox",
                props: {
                  width: "10vw",
                },
                defaultValue: false,
                allowUpdate: false,
                autoFilled: true,
              },
              {
                name: "totalValue",
                label: "Total Value",
                autoFilled: true,
                type: "number",
                decimal: true,
                defaultValue: 0,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "25vw",
              justify: "center",
            },
            childFields: [
              {
                name: "cancelled",
                label: "Cancelled",
                type: "checkbox",
                props: {
                  width: "25vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disabledForCreation: true,
                disability: {
                  type: "IN",
                  field: "submit",
                  values: [true],
                },
              },
              {
                name: "submit",
                label: "Submit",
                type: "checkbox",
                props: {
                  width: "25vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "IN",
                  field: "cancelled",
                  values: [true],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "fileInfos",
            label: "File Infos",
            type: "attachment",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
              spacing: "0.75vw",
              justify: "start",
            },
          },
        ],
      },
    ],
  },
];

export const cbSheetColumns = [
  {
    name: "id",
    label: "CBS No",
    type: "text",
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "itemCategory",
    label: "Category",
    type: "text",
  },
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStateName",
    label: "State",
    type: "text",
  },
  {
    name: "consuProjectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "consuProjectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "consuProjectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
  },
  {
    name: "taskType",
    label: "Task Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues, ...workTypeValues]),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const cbSheetSearchColumns = [
  {
    name: "cbsId",
    label: "CBS No",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
    foreignEntityType: "station",
  },
  {
    name: "projectType",
    label: "Type",
    defaultValue: "",
    type: "picklist",
    foreignEntityType: "consuProject",
    values: projectTypeValues,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "consuProjectCode",
    label: "Project Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "submit",
    label: "Submit",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];

export const cbSheetExcelColumns = [
  {
    name: "id",
    label: "CBS No",
    type: "text",
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docStateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "partnerInvNo",
    label: "Partner Inv No",
    type: "text",
  },
  {
    name: "partnerInvDate",
    label: "Partner Inv Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "contractorInvNo",
    label: "Contractor Inv No",
    type: "text",
  },
  {
    name: "contractorInvDate",
    label: "Contractor Inv Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docMaterialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => translatePicklistValue(value, materialTypeValues),
  },
  {
    name: "consuProjectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "consuProjectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "consuProjectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "consuVirtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
  },
  {
    name: "taskType",
    label: "Task Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues, ...workTypeValues]),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const cbSheetDetailExcelColumns = [
  {
    name: "cbsId",
    label: "CBS No",
    type: "text",
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docStateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "partnerInvNo",
    label: "Partner Inv No",
    type: "text",
  },
  {
    name: "partnerInvDate",
    label: "Partner Inv Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "contractorInvNo",
    label: "Contractor Inv No",
    type: "text",
  },
  {
    name: "contractorInvDate",
    label: "Contractor Inv Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docMaterialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => translatePicklistValue(value, materialTypeValues),
  },
  {
    name: "docProjectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "consuProjectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "consuProjectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "consuVirtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
  },
  {
    name: "taskType",
    label: "Task Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues, ...workTypeValues]),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },

  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Used Quantity",
    type: "number",
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];
