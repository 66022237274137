import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LookupField from "../inputFields/LookupField";
import TableInput from "../inputFields/TableInput";
import dayjs from "../../config/dayjsConfig";
import AttachmentComponent from "../inputFields/AttachmentComponent";
import { evaluateFilter } from "../../services/filterEvaluator";
import Stack from "@mui/material/Stack";
import { isEmptyObj } from "../../services/utils";
import { Field } from "../../types/field";

export const FormViewField = ({ field, width = "25vw", record, entityType, globalConstants }) => {
  let viewField: Field = field;
  width = field.props?.width ?? width;
  if (viewField.hiddenForView || (!_.isEmpty(viewField.visibility) && !evaluateFilter(viewField.visibility, record, globalConstants))) {
    return <></>;
  }

  switch (field.type) {
    case "text":
    case "number":
      return (
        <TextField
          id={field.name}
          name={field.name}
          label={field.label}
          disabled={true}
          variant={field.autoFilled || field.displayOnly ? "outlined" : "outlined"}
          type="text"
          sx={{
            mt: 2,
            width: width,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          value={getFieldValue(record, field)}
          InputLabelProps={{
            shrink: true, // Enable label animation
          }}
          size="small"
          //   inputProps={{
          //     min: "0", // Set the minimum value to 0
          //     step: "any", // Allow decimal input
          // }}
        />
      );

    case "date":
      return (
        <DatePicker
          name={field.name}
          label={field.label}
          value={record && record[field.name] ? dayjs(record[field.name]) : null}
          disabled={true}
          slotProps={{
            textField: {
              size: "small",
            },
          }}
          sx={{
            mt: 2,
            width: width,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          format="DD/MM/YYYY"
          onChange={(date) => {}}
        />
      );

    case "textArea":
      return (
        <TextField
          id={field.name}
          name={field.name}
          label={field.label}
          disabled={true}
          type="text"
          sx={{
            mt: 2,
            width: width,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          multiline
          rows={4}
          required
          value={record ? record[field.name] : ""}
          size="small"
          InputLabelProps={{
            shrink: true, // Enable label animation
          }}
        />
      );
    case "picklist":
      return (
        <FormControl sx={{ width: width }}>
          <InputLabel
            id={field.name}
            sx={{
              fontSize: "smaller",
              lineHeight: "1.5", // Adjust to match text alignment
              marginTop: !(record && record[field.name]) ? "-7px" : null, // Fine-tune for vertical centering
            }}
          >
            {field.label}
          </InputLabel>
          <Select
            sx={{
              padding: "2px 2px",
              "& .MuiInputBase-input": {
                fontSize: "smaller",
                padding: "5px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
            }}
            labelId={field.name}
            id={field.name}
            label={field.label}
            name={field.name}
            value={record ? record[field.name] : ""}
            size="small"
            disabled={true}
          >
            <MenuItem value="" disabled>
              {field.label}
            </MenuItem>
            {field.values.map((valueObject) => (
              <MenuItem key={valueObject.value} value={valueObject.value}>
                {valueObject.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case "checkbox":
      return (
        <FormGroup>
          <FormControlLabel
            required
            control={
              <Switch
                checked={record ? record[field.name] : false}
                size="small"
                name={field.name}
                disabled={true}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={field.label}
          />
        </FormGroup>
      );

    case "lookup":
      let value = record[field.name];
      value = value ? (typeof value === "number" ? value.toString() : value) : "";
      return <LookupField width={width} field={field} record={record} disabled={true} value={value} handleRecordSelection={(e) => {}} />;

    case "formTable":
      return !(record && record[field.name]) ? (
        <TableInput
          key="1"
          columns={field.tableFields}
          title={field.label}
          fieldName={field.name}
          values={[{}]}
          parentGlobalConstants={globalConstants}
          autoFilled={true}
          mode="view"
          tableWidth={width}
          handleRecordSelection={(e) => {}}
        />
      ) : (
        <TableInput
          key="2"
          columns={field.tableFields}
          title={field.label}
          fieldName={field.name}
          values={record[field.name]}
          parentGlobalConstants={globalConstants}
          autoFilled={true}
          mode="view"
          tableWidth={width}
          handleRecordSelection={(e) => {}}
        />
      );

    case "attachment":
      return !(record && record[field.name]) ? (
        <AttachmentComponent key="1" fieldName={field.name} values={[{}]} disabled={true} entityType={entityType} mode={"view"} />
      ) : (
        <AttachmentComponent key="2" fieldName={field.name} values={record[field.name]} disabled={true} entityType={entityType} mode={"view"} />
      );
    case "formSection":
      return (
        <Stack
          direction={field.alignment ? field.alignment : "row"}
          alignItems={"top"}
          justifyContent={field.props && field.props.justify ? field.props.justify : "center"}
          spacing={field.props && field.props.spacing ? field.props.spacing : "1vw"}
        >
          {field.childFields.map((childField) =>
            FormViewField({
              field: childField,
              width: field.props && field.props.width ? field.props.width : "12vw",
              record,
              entityType,
              globalConstants,
            })
          )}
        </Stack>
      );

    default:
      return <></>;
  }

  function getFieldValue(record, field) {
    if (!field) {
      return "";
    }
    return record && !isEmptyObj(record[field.name]) ? record[field.name] : null;
  }
};
