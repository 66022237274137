export const safeConvertToFixed = (value: any, decimalPlaces = 2) => {
  if (value != null && !isNaN(value)) {
    return parseFloat(value).toFixed(decimalPlaces);
  }
  return (0).toFixed(decimalPlaces);
};

export const safeConvertToFixedOrDash = (value: any, decimalPlaces = 2) => {
  if (value != null && !isNaN(value)) {
    return parseFloat(value).toFixed(decimalPlaces);
  }
  return ('-');
};
