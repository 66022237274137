export interface BaseConfigSpec {
  configKey: string;
  label: string;
  fieldType: FieldType;
  valueOptions?: any[]; // Optional: specify if there are predefined options for the value
  accessUserType: number; // 0, 1, 2, etc., indicating the access level required.
  multiValue?: boolean; // Indicates if the field supports multiple values
  defaultValue?: any;
  superUserOnly?: boolean; // Indicates if only super users can access this config
}

export interface NumberConfigSpec extends BaseConfigSpec {
  fieldType: FieldType.NUMBER;
  minValue?: number;
  maxValue?: number;
}

export interface DateConfigSpec extends BaseConfigSpec {
  fieldType: FieldType.DATE;
  minDate?: string;
  maxDate?: string;
}

export type ConfigSpec = BaseConfigSpec | NumberConfigSpec | DateConfigSpec;

export enum FieldType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  PICKLIST = "picklist",
  DATE = "date",
}
