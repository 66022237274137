import { ConfigKey, getConfigValue } from "../config/configHolder";
import { CompBucket } from "../types/compBuckets";

export const getEnabledCompBuckets = () => {
  return getConfigValue(ConfigKey.ENABLED_COMP_BUCKETS, []);
};

export const isAirtelEnabled = () => {
  return getEnabledCompBuckets().includes(CompBucket.BAL);
};

export const isOthersEnabled = () => {
  return getEnabledCompBuckets().includes(CompBucket.OTH);
};
