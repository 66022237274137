import { useState, useEffect } from "react";
import RecordTabularView from "../recordStandardForms/RecordTabularView";
import withAuth from "../../hocs/withAuth";
import RecordPage from "../recordStandardForms/RecordPage";
import { useParams } from "react-router-dom";
import { userColumns } from "../../standardEntities/user";
import { userSearchFields } from "../../standardEntities/user";
import { User } from "./UserTypes";
import { isAdminUser } from "../../services/utils";
import RecordAdditionForm from "../recordStandardForms/RecordAdditionForm";

const getDefaultRecordSearchRequest = () => {
  return { name: "", userId: "", email: "" };
};

function UserViewForm({ currentUser }) {
  let { mode: urlMode } = useParams<{ mode: string }>();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [mode, setMode] = useState(urlMode || "view");
  const [recordSearchRequest, setRecordSeachRequest] = useState(getDefaultRecordSearchRequest());
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setSelectedRecord(null);
  }, [currentUser]);

  const setSelectedRecordIfAllowed = (record: User) => {
    //we set the record only if our current user is allowed to view and edit a user
    if (isAdminUser(currentUser) || currentUser.superUser) {
      setSelectedRecord(record);
    }
  };

  useEffect(() => {
    setMode(urlMode || "view");
  }, [urlMode]);

  const incrementRefreshKey = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  return (
    <>
      {selectedRecord && mode === "edit" && (
        <RecordAdditionForm
          selectedRecord={selectedRecord}
          entityType="user"
          setSelectedRecord={setSelectedRecord}
          setMode={setMode}
          originalIsUpdateForm={true}
          refreshKey={refreshKey}
          incrementRefreshKey={incrementRefreshKey}
        />
      )}
      {selectedRecord && mode === "view" && (
        <RecordPage
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          creationAllowed={false}
          entityType="user"
          pageTitle={"User"}
          formMinWidth="40vw"
          isEditable={() => isAdminUser(currentUser) || currentUser.superUser}
          currentUser={currentUser}
          setMode={setMode}
          refreshKey={refreshKey}
        />
      )}
      {!selectedRecord && (
        <RecordTabularView
          columns={userColumns}
          pageTitle="Users"
          creationAllowed={false}
          recordSearchRequest={recordSearchRequest}
          setRecordSeachRequest={setRecordSeachRequest}
          setSelectedRecord={setSelectedRecordIfAllowed}
          searchFields={userSearchFields}
          getDefaultRecordSearchRequest={getDefaultRecordSearchRequest}
          entityType="user"
          setMode={setMode}
          refreshKey={refreshKey}
        />
      )}
    </>
  );
}

export default withAuth(UserViewForm);
