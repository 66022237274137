import { translatePicklistValue } from "../services/fieldsUtils";
import { Column } from "../types/column";
import { Field } from "../types/field";
export const workSiteTypeValues = [
  { value: "4G", label: "4G Site" },
  { value: "5G", label: "5G Site" },
  { value: "OT", label: "Others" },
];

export const workSiteFields: Field[] = [
  {
    name: "siteId",
    label: "Site Id",
    allowUpdate: true,
    type: "text",
    props: {
      width: "25vw",
    },
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "siteType",
        label: "Site Type",
        type: "picklist",
        props: {
          width: "12vw",
        },
        values: workSiteTypeValues,
        defaultValue: "OT",
      },
      {
        name: "companyCode",
        label: "Company",
        type: "text",
        props: {
          width: "12vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    // visibility: {
    //   type: "IN",
    //   field: "workSiteType",
    //   values: ["OT"],
    // },
    childFields: [
      {
        name: "companyName",
        label: "Company Name",
        type: "lookup",
        lookupType: "company",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "ownerFlg",
              values: [true],
            },
            {
              type: "IN",
              field: "compBucket",
              values: ["OTH"],
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "companyCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "foreignFlg",
            fieldName: "foreignFlg",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstFlg",
            fieldName: "gstFlg",
          },
        ],
        props: {
          width: "25vw",
        },
        linkedFields: [
          {
            name: "stationName",
            value: null,
          },
          {
            name: "gstNo",
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: "address1",
    label: "Address1",
    allowUpdate: true,
    type: "text",
    props: {
      width: "25vw",
    },
  },
  {
    name: "address2",
    label: "Address2",
    allowUpdate: true,
    type: "text",
    defaultValue: "",
    allowNull: true,
    props: {
      width: "25vw",
    },
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stationName",
        label: "Station Name",
        allowUpdate: true,
        type: "lookup",
        lookupType: "station",
        props: {
          width: "17vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stationCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateName",
            fieldName: "stateName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateCode",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "country",
            fieldName: "country",
          },
        ],
        disability: {
          type: "MISSING",
          field: "companyName",
        },
        linkedFields: [
          {
            name: "gstNo",
            value: null,
          },
        ],
      },
      {
        name: "stationCode",
        label: "Station Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "12vw",
        },
      },
      {
        name: "country",
        label: "Country",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "gstNo",
        label: "GST Number",
        type: "lookup",
        lookupType: "companyGst",
        lookupCategory: "workSiteCompany",
        defaultValue: null,
        props: {
          width: "17vw",
        },
        disability: {
          type: "OR",
          filters: [
            { type: "IN", field: "foreignFlg", values: [true] },
            { type: "IN", field: "gstFlg", values: [false] },
            { type: "MISSING", field: "companyName" },
            { type: "IN", field: "workSiteType", values: ["CS", "PS"] },
          ],
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "companyCode",
            },
            {
              type: "EQUALS",
              field: "gstCode",
              resolveValue: true,
              valueFieldName: "gstCode",
            },
          ],
        },
      },
      {
        name: "gstCode",
        label: "GST Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    props: {
      width: "25vw",
    },
    childFields: [
      {
        name: "siteEmailId",
        label: "Email Id",
        type: "text",
        allowUpdate: true,
        allowNull: true,
        props: {
          width: "17vw",
        },
      },
      {
        name: "id",
        label: "Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    props: {
      width: "25vw",
    },
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
    ],
  },
];

export const workSiteColumns = [
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "siteType",
    label: "Site Type",
    type: "text",
    format: (value) => translatePicklistValue(value, workSiteTypeValues),
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "id",
    label: "Id",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workSiteExcelColumns: Column[] = [
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "siteType",
    label: "Site Type",
    type: "picklist",
    values: workSiteTypeValues,
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "id",
    label: "Id",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "checkbox",
  },
];

export const workSiteSearchColumns = [
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "siteType",
    label: "Site Type",
    type: "picklist",
    values: workSiteTypeValues,
  },
  {
    name: "company.companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "id",
    label: "Id",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
