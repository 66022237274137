import React, { useState } from "react";
import { IconButton, TextField, Grid, Box, Typography } from "@mui/material";
import { evaluateFilter } from "../../services/filterEvaluator";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import LookupField from "./LookupField";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import _ from "lodash";
import {
  canChangeValue,
  evaluateValueScript,
  isConstraintsSatisfied,
  isEmptyObj,
  resolveLinkedFieldValue,
  toUpperCase,
  resolveValueInternal,
} from "../../services/utils";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import LookupTable from "./LookupTable";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { Field } from "../../types/field";

const getInitialValues = (values) => {
  if (_.isEmpty(values) || _.isEmpty(values[0]) || Object.keys(values[0]).length === 0) {
    // return [{ serialNo: 1 }];
    return [];
  }
  return values;
};

function filterColumnsForVisibility(columns: Field[], mode: string, parentGlobalConstants: any) {
  let filteredColumns = columns.filter((column: Field) => {
    if (mode === "view") {
      if (column.hiddenForView) {
        return false;
      }
      return true;
    }
    if (!column.visibility) {
      return true;
    }
    return evaluateFilter(column.visibility, {}, parentGlobalConstants);
  });
  return filteredColumns;
}

const defaultColumns: any[] = [
  { name: "id", label: "ID", required: true, props: { widthFactor: 1 } },
  { name: "name", label: "Name", required: true, props: { widthFactor: 1 } },
  {
    name: "description",
    label: "Description",
    required: false,
    props: { widthFactor: 2 },
  },
  { name: "abc", label: "abc", required: false, props: { widthFactor: 2 } },
  { name: "cdf", label: "cf", required: false, props: { widthFactor: 2 } },
];

function TableInput({
  columns = defaultColumns,
  title = "Table Title",
  fieldName,
  handleRecordSelection = (event) => {},
  values = [],
  parentGlobalConstants = {},
  autoFilled = false,
  tableDisabled = false,
  entityType = "",
  formErrors = {},
  mode = "add",
  setFormErrors = (prevFormErrors) => {},
  setError = (err) => {},
  tableWidth = "82vw",
  refreshKey = 0,
}) {
  const [snackOpen, setSnackOpen] = useState(false);
  const [rows, setRows] = useState(() => getInitialValues(values));
  const [tableErrors, setTableErrors] = useState(Array.isArray(formErrors[fieldName]) ? formErrors[fieldName] : [{}]);

  let globalConstants: any = { ...parentGlobalConstants };
  columns = filterColumnsForVisibility(columns, mode, parentGlobalConstants);

  const handleRemoveRow = (rowIndex) => {
    const newRows = rows.filter((row, i) => {
      return i !== rowIndex;
    });
    setNewRows(newRows);
  };

  const handleDeleteRowToggle = (rowIndex, deleted) => {
    const newRows = rows.map((row, i) => {
      return i !== rowIndex ? row : { ...row, deleted: deleted };
    });
    setSnackOpen(deleted);
    setNewRows(newRows);
  };

  const handleAddRow = () => {
    let newRow = _.isEmpty(rows) ? { serialNo: 1 } : { serialNo: rows[rows.length - 1].serialNo + 1 };
    let rowIndex = tableErrors.length - 1;

    if (tableErrors[rowIndex] && Object.values(tableErrors[rowIndex]).some((value) => !isEmptyObj(value))) {
      const errorValue = Object.values(tableErrors[rowIndex]).find((value) => !isEmptyObj(value));
      setError(errorValue);
      if (errorValue) {
        return;
      }
    }
    columns.forEach((column) => {
      if (column.defaultValue) {
        newRow[column.name] = column.defaultValue;
      }

      if (column.defaultValueFieldName) {
        let resolvedValue = resolveValueInternal(column.defaultValueFieldName, newRow, globalConstants);
        newRow[column.name] = !isEmptyObj(resolvedValue) && Array.isArray(resolvedValue) ? resolvedValue[0] : resolvedValue || "";
      }
    });
    let newRows = _.isEmpty(rows) ? [newRow] : [...rows, newRow];

    setNewRows(newRows);
    setTableErrors([...tableErrors, {}]);
  };

  const setFormErrorsForRow = (rowIndex, errorsfnc) => {
    let errors = errorsfnc();
    setFormErrors((error) => {
      let newTableErrors = [...tableErrors];
      newTableErrors[rowIndex] = {};
      newTableErrors[rowIndex] = errors;
      newTableErrors[rowIndex] = errors;
      error[fieldName] = newTableErrors;
      setTableErrors(newTableErrors);
      return { ...error };
    });
  };

  const handleInputChange = (index, name, value, currentField, decimal = currentField.decimal, isLinkedField = false) => {
    if (!canChangeValue({ target: { value, name } }, currentField, decimal)) {
      return;
    }
    if (currentField.constraints) {
      if (
        !isConstraintsSatisfied(
          currentField.constraints,
          value,
          currentField,
          setError,
          (errorsfnc) => setFormErrorsForRow(index, errorsfnc),
          rows[index]
        )
      ) {
        // return;
      } else {
        setFormErrors((error) => {
          let newTableErrors = [...tableErrors];
          newTableErrors[index] = newTableErrors[index] ? newTableErrors[index] : {};
          newTableErrors[index][currentField.name] = "";
          error[fieldName] = newTableErrors;
          setTableErrors(newTableErrors);
          return { ...error };
        });
        setError("");
      }
    }
    setNewRowsUsingIndex(index, name, value);
    if (currentField && currentField.linkedFields && !isLinkedField) {
      currentField.linkedFields.forEach((childField) => {
        let selectedValue = resolveLinkedFieldValue(childField, { ...rows[index], [name]: value });
        console.log("selectedValue", { selectedValue, childName: childField.name, childField, name, value });
        handleInputChange(index, childField.name, selectedValue, childField, false, true);
        // setFormErrors((prevFormErrors) => {
        //   prevFormErrors[name] = "";
        //   return { ...prevFormErrors };
        // });
      });
    }
  };

  const handleClose = (e) => {
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const isButtonActive = () => {
    if (mode === "view" || tableDisabled || autoFilled) {
      return false;
    }
    if (rows.length === 0) {
      return true;
    }
    const lastRow = rows[rows.length - 1];
    return columns.every((column) => column.allowNull || lastRow[column.name]);
  };

  const totalWidthFactors =
    columns.reduce((total, column) => total + (column.props && column.props.widthFactor ? column.props.widthFactor : 1), 0) + 0.5;

  //todo: move this out of here to entity specific components
  if (entityType === "material-outward") {
    globalConstants.mirRowIds = rows.filter((row) => row.mirRowId !== undefined).map((row) => row.mirRowId);
    globalConstants.totalAmount = rows.filter((row) => row.totalAmount !== undefined).reduce((result, row) => result + (row.totalAmount || 0), 0);
  } else if (entityType === "project") {
    globalConstants.selectedProjectUsers = rows
      .filter((row) => row.projectUser !== undefined && row.projectUser != null)
      .map((row) => row.projectUser);
  } else if (entityType === "cb-sheet") {
    globalConstants.itemIds = rows.filter((row) => !isEmptyObj(row.itemId)).map((row) => row.itemId);
    globalConstants.itemProjectCodes = rows.filter((row) => !isEmptyObj(row.projectCode)).map((row) => row.itemCustCode + "-" + row.projectCode);
  }

  return (
    <Box p={1} borderRadius={0} border="1px solid #ddd" width={tableWidth} sx={{ backgroundColor: "#F0F8FF" }}>
      <Grid container spacing={1} marginLeft={1}>
        {columns.map((column, index) => (
          <Grid item xs={(12 / totalWidthFactors) * (column.props && column.props.widthFactor ? column.props.widthFactor : 1)} key={index}>
            <Typography variant="body2" align="center">
              <strong>{column.label}</strong>
            </Typography>
          </Grid>
        ))}
        {rows.map((row, rowIndex) => {
          row["sno"] = rowIndex + 1;
          return (
            <Grid container item xs={12} spacing={0.2} key={row.serialNo} style={{ padding: 0.5 }}>
              {columns.map((column, columnIndex) => {
                let width = (12 / totalWidthFactors) * (column.props && column.props.widthFactor ? column.props.widthFactor : 1);
                return (
                  <Grid item xs={width} key={columnIndex} sx={{ margin: 0 }}>
                    {getJSXForField(column, row, rowIndex, width)}
                  </Grid>
                );
              })}
              <Grid item xs={(12 / totalWidthFactors) * 0.5} key={`del-${rowIndex}`} sx={{ margin: 0 }}>
                {row["id"] == null && mode !== "view" && !tableDisabled && !autoFilled && (
                  <IconButton aria-label="delete" size="small" name={`row-${rowIndex}`} onClick={(event) => handleRemoveRow(rowIndex)}>
                    <CancelIcon fontSize="medium" name={`location-${rowIndex}`} />
                  </IconButton>
                )}
                {row["id"] != null &&
                  mode !== "view" &&
                  !tableDisabled &&
                  !autoFilled &&
                  (row.deleted ? (
                    <IconButton
                      disabled={!row.deleted}
                      aria-label="restore"
                      size="small"
                      name={`row-${rowIndex}`}
                      onClick={(event) => {
                        handleDeleteRowToggle(rowIndex, false);
                      }}
                    >
                      <RestoreFromTrashIcon fontSize="medium" color="success" name={`location-${rowIndex}`} />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={row.deleted}
                      aria-label="delete"
                      size="small"
                      name={`row-${rowIndex}`}
                      onClick={(event) => {
                        handleDeleteRowToggle(rowIndex, true);
                      }}
                    >
                      <DeleteIcon fontSize="medium" name={`location-${rowIndex}`} />
                    </IconButton>
                  ))}
                <Snackbar
                  open={snackOpen}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message="The row will permanently delete only after pressing update"
                  action={action}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
        {isButtonActive() && (
          <Grid container item xs={12} justifyContent="flex-end">
            <IconButton
              onClick={handleAddRow}
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                width: "auto",
                height: "auto",
                padding: "8px",
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  function setNewRows(newRows) {
    setRows(newRows);
    handleRecordSelection({
      target: { name: fieldName, value: [...newRows], type: "formTable" },
    });
  }

  function setNewRowsUsingIndex(index, name, value) {
    setRows((prevRows) => {
      let newRows = prevRows.map((row, idx) => (idx === index ? { ...row, [name]: value } : row));
      handleRecordSelection({
        target: { name: fieldName, value: [...newRows], type: "formTable" },
      });
      return newRows;
    });
  }

  function getJSXForField(field, row, rowIndex, width) {
    let disabled = tableDisabled;

    if (field.hidden) {
      width = 0;
    }

    if (!_.isEmpty(field.disability)) {
      disabled = disabled || evaluateFilter(field.disability, row, globalConstants);
    }

    let isAutoFilled = autoFilled || (mode === "update" && !field.allowUpdate && row.id != null);
    let rowErrors = tableErrors[rowIndex];
    let decimal = false;
    if ((row["decimalFlg"] && field.decimalFlg) || field.decimal) {
      decimal = true;
    }

    let editDisabled = field.displayOnly || field.autoFilled || disabled || isAutoFilled;

    switch (field.type) {
      case "text":
      case "number":
      case "textArea":
        return (
          <TextField
            key={field.name + rowIndex + "text"}
            id={field.name}
            name={field.name}
            value={getFieldValue(row, field, rowIndex)}
            onChange={(event) => handleInputChange(rowIndex, field.name, event.target.value, field, decimal)}
            fullWidth
            size="small"
            type={"text"}
            required={field.required}
            disabled={editDisabled}
            error={rowErrors && !_.isEmpty(rowErrors[field.name])}
            // helperText={
            //   rowErrors && rowErrors[field.name] ? rowErrors[field.name] : null
            // }

            sx={{
              borderRadius: 0,
              textDecoration: row.deleted ? "line-through" : "none",
              color: row.deleted ? "red" : "inherit",
              backgroundColor: editDisabled ? "#F7F6FF" : "#E8F0FE",
              "& .MuiInputBase-input": {
                fontSize: "smaller",
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
              //      backgroundColor: disabled ? "#F0F0F0" : "inherit",
            }}
            style={{ borderRadius: 0 }}
            onInput={(e) => toUpperCase(e, field)}
            // InputProps={{ sx: { borderRadius: 0 } }}
            inputProps={{
              min: "0", // Set the minimum value to 0
              step: decimal ? 0.1 : 1, // Allow decimal input
            }}
          />
        );

      case "date":
        return <></>;
      case "picklist":
        return (
          <FormControl fullWidth>
            <Select
              labelId={field.name}
              id={field.name}
              label={field.label}
              name={field.name}
              fullWidth
              value={row ? row[field.name] : ""}
              size="small"
              key={field.name + rowIndex + "picklist"}
              sx={{
                textDecoration: row.deleted ? "line-through" : "none",
                color: row.deleted ? "red" : "inherit",
                padding: "2px 2px",
                "& .MuiInputBase-input": {
                  fontSize: "smaller",
                  padding: "5px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "smaller",
                },
                backgroundColor: editDisabled ? "#F7F6FF" : "#E8F0FE",
              }}
              disabled={isAutoFilled || disabled}
              onChange={(event) => handleInputChange(rowIndex, field.name, event.target.value, field)}
            >
              <MenuItem value="" disabled>
                {field.label}
              </MenuItem>
              {field.values.map((valueObject) => (
                <MenuItem key={valueObject.value} value={valueObject.value}>
                  {valueObject.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "checkbox":
        return <></>;

      case "lookup":
        return (
          <LookupField
            sx={{ borderRadius: 0, backgroundColor: editDisabled ? "#F7F6FF" : "#E8F0FE" }}
            field={field}
            record={row}
            size="small"
            showLabel={false}
            value={row[field.name]}
            cancelled={row.deleted}
            disabled={disabled || isAutoFilled}
            error={rowErrors && !_.isEmpty(rowErrors[field.name])}
            // helperText={
            //   rowErrors && rowErrors[field.name] ? rowErrors[field.name] : null
            // }
            parentGlobalConstants={{ ...globalConstants, ...row }}
            handleRecordSelection={(event) => handleInputChange(rowIndex, event.target.name, event.target.value, field)}
            refreshKey={refreshKey}
            key={field.name + rowIndex + "lookup"}
          />
        );
      case "lookupTable":
        return (
          <LookupTable
            sx={{ backgroundColor: editDisabled ? "#F7F6FF" : "#E8F0FE" }}
            size="small"
            recordType="material-inward"
            pageTitle="Material Inward Rows"
            columns={field.tableColumns}
            value={row[field.name]}
            disabled={disabled || isAutoFilled}
            field={field}
            parentGlobalConstants={{ ...globalConstants, ...row }}
            handleSelection={(event) => handleInputChange(rowIndex, event.target.name, event.target.value, field)}
            key={field.name + rowIndex + "lookup"}
          />
        );
      default:
        return <></>;
    }
  }

  function getFieldValue(record, field, rowIndex) {
    if (!field) {
      return "";
    }
    if (mode === "view") {
      return record && record[field.name] ? record[field.name] : field.type === "number" ? 0 : "";
    }

    if (record && field.valueScript) {
      try {
        let result = evaluateValueScript(field.valueScript, record, globalConstants);
        if (rows[rowIndex][field.name] !== result) {
          setNewRowsUsingIndex(rowIndex, field.name, result);
        }
      } catch (error) {
        console.error("Error computing field value:", error);
        return "";
      }
    }
    return record ? record[field.name] : field.type === "number" ? 0 : "";
  }
}

export default TableInput;
