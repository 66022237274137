import { Switch, Route, withRouter } from "react-router-dom";
import WithInitMetadata from "./WithInitMetadata";
import UserViewForm from "../components/users/UserViewForm";
import RecordManager from "../components/recordStandardForms/RecordManager";
import StockReportForm from "../components/reports/StockListingForm";
import StockAgeingForm from "../components/reports/StockAgeingForm";
import StockOpeningClosingForm from "../components/reports/StockOpeningClosingForm";
import StockInOutForm from "../components/reports/StockInOutForm";
import StockLedgerForm from "../components/reports/StockLedgerForm";
import MyProfileForm from "../components/users/MyProfileForm";
import ConfigurationScreen from "../components/configuration/ConfigurationScreen";

const ProtectedRoutes = () => {
  return (
    <div className="container">
      <WithInitMetadata>
        <Switch>
          <Route path="/user/:mode" render={() => <UserViewForm />} />
          <Route path="/user/" render={() => <UserViewForm />} />
          <Route path="/configuration/" render={() => <ConfigurationScreen />} />
          <Route path="/my-profile/:mode" render={() => <MyProfileForm />} />
          <Route path="/my-profile/" render={() => <MyProfileForm />} />
          <Route path="/report/stock" render={() => <StockReportForm />} />
          <Route path="/report/stock-ageing" render={() => <StockAgeingForm />} />
          <Route path="/report/stock-opening-closing" render={() => <StockOpeningClosingForm />} />
          <Route path="/report/stock-in-out" render={() => <StockInOutForm />} />
          <Route path="/report/stock-ledger" render={() => <StockLedgerForm />} />
          <Route path="/:recordType/:mode" render={() => <RecordManager />} />
          <Route path="/:recordType" render={() => <RecordManager />} />
        </Switch>
      </WithInitMetadata>
    </div>
  );
};

export default withRouter(ProtectedRoutes);
