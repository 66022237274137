import dayjs from "dayjs";
import moment from "moment-timezone";
import { evaluateValueScript, getConstraintByType } from "./utils";
import { DateRangeConstraint } from "../types/constraint";
import { ConfigKey, getConfigValue } from "../config/configHolder";
import { Dayjs } from "dayjs";
export const dateFormat = "DD/MM/YYYY";
export const getWorkStartDateString = () => new Date(getConfigValue(ConfigKey.WORK_START_DATE, "2024-01-01") as unknown as string);
export const getSystemStartDateString = () => new Date(getConfigValue(ConfigKey.SYSTEM_START_DATE, "2021-01-01") as unknown as string);
export const timeZone = "Asia/Kolkata";

export const TODAY_FIELD = "today";
export const WORK_START_DATE_FIELD = "workStartDate";
export const BEFORE_WORK_START_DATE_FIELD = "beforeWorkStartDate";
export const INWARD_CREATION_MIN_DATE = "inwardCreationMinDate";
export const OUTWARD_CREATION_MIN_DATE = "outwardCreationMinDate";
export const GATE_PASS_CREATION_MIN_DATE = "gatePassCreationMinDate";
export const SYSTEM_START_DATE_FIELD = "systemStartDate";

export const getYesterday = () => {
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return dayjs(yesterday).startOf("day");
};

export const getSystemStartDate = () => {
  return dayjs(getSystemStartDateString()).startOf("day");
};

export const getWorkStartDate = () => {
  return dayjs(getWorkStartDateString()).startOf("day");
};

export const getPreviousDay = (date: Dayjs, noOfDays: number = 1): Dayjs => {
  return dayjs(date).subtract(noOfDays, "day");
};

export const getOneMonthAgoOrOpening = () => {
  let getOneMonthAgoValue = dayjs().subtract(1, "month").startOf("day");
  let workStartDateValue = dayjs(getWorkStartDateString()).startOf("day");
  return getOneMonthAgoValue.isAfter(workStartDateValue) ? getOneMonthAgoValue : workStartDateValue;
};

export const getMonthsLater = (noOfMonths: number = 1) => {
  return dayjs().add(noOfMonths, "month").startOf("day");
};

export const getToday = () => {
  return dayjs().startOf("day");
};

export const formatDate = (value) => {
  if (dayjs.isDayjs(value)) {
    value = value.toDate();
  }
  if (value === null || value === undefined) {
    return "";
  }
  return moment(value).tz(timeZone).format(dateFormat);
};

export const getMinMaxDateForField = (field, record, globalConstants) => {
  let dateRangeConstraints = getConstraintByType(field.constraints, "DATE_RANGE", record);
  if (!dateRangeConstraints) {
    return { minDate: null, maxDate: null };
  }
  return getMinMaxDateConstraintWithGlobal(dateRangeConstraints, record, globalConstants);
};

export const getMinMaxDateConstraint = (constraint: DateRangeConstraint, record) => {
  return getMinMaxDateConstraintWithGlobal(constraint, record, {});
};

export const getMinMaxDateConstraintWithGlobal = (
  { minDate, minDateScript, maxDate, maxDateScript, beforeNow, afterNow }: DateRangeConstraint,
  record: any,
  globalConstants: any
) => {
  minDate = minDate ? dayjs(minDate) : null;
  let newGlobalConstants = {
    ...globalConstants,
    [TODAY_FIELD]: dayjs().startOf("day"),
    [WORK_START_DATE_FIELD]: getWorkStartDate(),
    [BEFORE_WORK_START_DATE_FIELD]: getPreviousDay(getWorkStartDate()),
    [INWARD_CREATION_MIN_DATE]: getPreviousDay(getToday(), Number(getConfigValue(ConfigKey.INWARD_CREATION_BUFFER_DAYS, "0"))),
    [OUTWARD_CREATION_MIN_DATE]: getPreviousDay(getToday(), Number(getConfigValue(ConfigKey.OUTWARD_CREATION_BUFFER_DAYS, "0"))),
    [GATE_PASS_CREATION_MIN_DATE]: getPreviousDay(getToday(), Number(getConfigValue(ConfigKey.GATE_PASS_CREATION_BUFFER_DAYS, "0"))),
    [SYSTEM_START_DATE_FIELD]: getSystemStartDate(),
  };
  if (minDateScript) {
    let scriptValue = evaluateValueScript(minDateScript, record, newGlobalConstants);
    minDate = scriptValue ? dayjs(scriptValue) : null;
  }
  maxDate = maxDate ? dayjs(maxDate) : null;
  if (maxDateScript) {
    let scriptValue = evaluateValueScript(maxDateScript, record, newGlobalConstants);
    maxDate = scriptValue ? dayjs(scriptValue) : null;
  }

  // Handle minDate
  getMinDate();
  // Handle maxDate
  getMaxDate();
  return { minDate, maxDate };

  function getMaxDate() {
    const today = dayjs().endOf("day");
    if (maxDate) {
      if (beforeNow) {
        maxDate = minDateComp(maxDate, today); // Min of calculated maxDate and today
      }
      // else, maxDate remains the same
    } else {
      if (beforeNow) {
        maxDate = today; // Set maxDate to today
      } else {
        maxDate = null;
      }
    }
  }

  function getMinDate() {
    const today = dayjs().startOf("day");
    if (minDate) {
      if (afterNow) {
        minDate = maxDateComp(minDate, today); // Max of calculated minDate and today
      }
      // else, minDate remains the same
    } else {
      if (afterNow) {
        minDate = today; // Set minDate to today
      } else {
        minDate = null;
      }
    }
  }
};

function minDateComp(date1, date2) {
  if (!date1) return date2;
  if (!date2) return date1;
  return dayjs(date1).isBefore(dayjs(date2)) ? date1 : date2;
}

function maxDateComp(date1, date2) {
  if (!date1) return date2;
  if (!date2) return date1;
  return dayjs(date1).isAfter(dayjs(date2)) ? date1 : date2;
}
