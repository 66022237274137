import { DefaultHandler } from "./DefaultHandler";
import _ from "lodash";
import dayjs from "../../config/dayjsConfig";
import { getPreviousDay, getSystemStartDate, getToday, getWorkStartDate } from "../../services/dateUtils";
import { getWorkStartDateString } from "../../services/dateUtils";
import { HandleEntitySpecificChangeDTO } from "./types";

export const getDocDate = (record) => {
  return record.createAt ? dayjs(record.createdAt).startOf("day") : getToday();
};

class MaterialInwardHandler extends DefaultHandler {
  getFormWidth(): string {
    return "82vw";
  }
  isValidRecord = (record, setFormErrors, setError) => {
    if (!record) {
      return false;
    }
    const totalValues = _.isEmpty(record.details)
      ? 0
      : record.details
          .filter((detail) => !detail.deleted)
          .reduce((total, detail) => {
            return total + parseFloat(detail.basicAmount);
          }, 0);
    const totalDetailValues = Math.round(totalValues * 100) / 100;
    let isTotalValid = totalDetailValues === parseFloat(record.basicValue);
    if (!isTotalValid) {
      setFormErrors((formError) => {
        formError["basicValue"] = `Should match sum of all Basic Amounts: ${totalDetailValues}`;
        return { ...formError };
      });
      setError(`Basic value (${parseFloat(record.basicValue)}) does not match sum of all basic amounts in detail (${totalDetailValues})`);
      return false;
    } else {
      setFormErrors((formError) => {
        formError["inventoryValue"] = "";
        return { ...formError };
      });
      setError("");
    }
    return true;
  };

  override handleEntitySpecificChange = async (handleEntitySpecificChangeDTO: HandleEntitySpecificChangeDTO) => {
    let { record, name, value, type, checked, handleRecordChange } = handleEntitySpecificChangeDTO;
    if (name === "transactionType") {
      if (
        value === "OS" &&
        (!record["docDate"] ||
          dayjs(record["docDate"]).isAfter(getWorkStartDateString()) ||
          dayjs(record["docDate"]).isSame(getWorkStartDateString()))
      ) {
        handleRecordChange({
          target: { name: "docDate", value: getPreviousDay(getWorkStartDate()), type, checked },
        });
      } else if (value !== "OS" && (!record["docDate"] || dayjs(record["docDate"]).isBefore(getWorkStartDateString()))) {
        handleRecordChange({
          target: { name: "docDate", value: getDocDate(record), type, checked },
        });
      }
    } else if (name === "projectType") {
      if (value === "FTTH" && record.docLocatorType === "CS") {
        handleRecordChange({
          target: { name: "receiverDocType", value: "CO", type, checked },
        });
      } else {
        handleRecordChange({
          target: { name: "receiverDocType", value: "NA", type, checked },
        });
      }
    }
  };

  override formDisabled(record): boolean {
    return record && (record.transactionType === "IS" || record.transactionType === "PS" || record.transactionType === "CS");
  }

  override getViewDefaultStartDate(): dayjs.Dayjs {
    return getSystemStartDate();
  }

  override getEntityType(): string {
    return "material-inward";
  }
}
const materialInwardHandler = new MaterialInwardHandler();
export default materialInwardHandler;
