import React, { useState } from "react";
import { TextField, List, ListItem, ListItemText, IconButton, Box, Button, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { isEmptyObj } from "../../services/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";

const MultiTextInput = ({ fieldLabel, value, setValue, sx }) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const addValue = () => {
    let values = getValues(value);
    if (input.trim()) {
      if (values.includes(input.trim())) {
        setError("Value already exists in the list.");
      } else {
        let newValues = [input.trim(), ...values];
        setValue(newValues.join(","));
        setInput("");
        setError("");
      }
    }
  };

  const removeValue = (index: number) => {
    let values = getValues(value);
    let newValues = values.filter((_, i) => i !== index);
    setValue(newValues.join(","));
  };

  const getValues = (value: string | any[]) => {
    console.log("archit value", value);
    if (value instanceof Array) {
      return value;
    }
    return !isEmptyObj(value) ? value.split(",") : [];
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={() => setOpen(true)} sx={{ backgroundColor: "#007bff", color: "#fff" }}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        // fullWidth={true}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{fieldLabel}</DialogTitle>
        <DialogContent dividers>
          <DialogContent>
            <Box sx={{ width: "100%", maxWidth: 400, margin: "auto", textAlign: "top", mt: -1 }}>
              <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Add Value"
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                    setError(""); // Clear error when user types
                  }}
                  sx={sx}
                  error={!!error}
                  helperText={error}
                  // size="small"
                />
                {/* {!isEmptyObj(input) && ( */}
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={addValue}
                  style={{
                    backgroundColor: !isEmptyObj(input) ? "#007bff" : "grey",
                    color: "#fff",
                    width: "2.5vw",
                    height: "2.5vw",
                    padding: "8px",
                  }}
                  disabled={isEmptyObj(input)}
                  size="small"
                >
                  <AddIcon />
                </IconButton>
                {/* )} */}
              </Box>
              <List>
                {getValues(value).map((value, index) => (
                  <ListItem
                    key={"item_" + index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" sx={{ color: "#D32F2F" }} onClick={() => removeValue(index)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={
                        <>
                          <strong>{index + 1}.</strong>
                          <span style={{ marginLeft: "8px" }}>{value}</span>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiTextInput;
