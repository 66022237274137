import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { getStockAgeingFormFields, stockAgeingAvailableColumns, stockAgeingPhysicalColumns } from "./formFields/stockAgeingFormFields";
import { getWorkStartDate, getToday } from "../../services/dateUtils";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.stockType];
};

const reportCategoryToExcelColumns = {
  A: stockAgeingAvailableColumns,
  P: stockAgeingPhysicalColumns,
};

const StockAgeingForm = ({ formMinWidth = "40vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={getStockAgeingFormFields()}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Ageing Report"}
      getReportName={(searchRequest) => `Stock Ageing Report`}
      reportType={"stock-ageing"}
      preProcessReportSearchRequest={(searchRequest) => {
        searchRequest.fromDocDate = searchRequest.fromDocDate ? searchRequest.fromDocDate : getWorkStartDate();
        searchRequest.toDocDate = searchRequest.toDocDate ? searchRequest.toDocDate : getToday();
        searchRequest.stockDate = searchRequest.stockDate ? searchRequest.stockDate : getToday();
        return searchRequest;
      }}
    />
  );
};

export default withAuth(StockAgeingForm);
