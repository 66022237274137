import { materialTypeValues } from "../../standardEntities/materialInward";
import { projectTypeValues } from "../../standardEntities/project";
import { workTypeValues } from "../../standardEntities/work";
import { formatDate } from "../dateUtils";
import { translatePicklistValue } from "../fieldsUtils";
import { nullSafeString } from "../stringUtils";
import { itemCategoryValues } from "../../standardEntities/cbSheetFields";
import { locatorTypeValues } from "../../standardEntities/locator";
import { safeConvertToFixed, safeConvertToFixedOrDash } from "../numberUtils";
import { AbstractPDFContentGenerator, AssembledPdfData } from "./PDFContentGenerator";

export class CBSheetPdfHandler extends AbstractPDFContentGenerator {
  getRecordType(): string {
    return "cb-sheet";
  }

  assemblePdfData(record: any): AssembledPdfData {
    let cbSheetPdfTaskHandler = getCBSheetPDFTaskHandler(record.consuProjectType);
    let title = [["CONSUMPTION BOOK SHEET"]];
    let senderReceiverData = [this.getBasicDetails(record), cbSheetPdfTaskHandler.getTaskWiseDetails(record)];
    let dtHead = [
      [
        "SN",
        "Description Of Material",
        "Part Code",
        "Project Name",
        "Project",
        "Virtual Locator",
        "Out Qty",
        "UOM",
        "Out Value",
        "Item Id",
        "Item Qty",
      ],
    ];

    let details = record.details.map((row, index) => {
      return [
        index + 1,

        row.itemName,
        row.itemCustCode,
        row.projectName,
        row.projectCode,
        row.projectVirtualLocator,
        safeConvertToFixed(row.totalQuantity, 1),
        row.uom,
        safeConvertToFixed(row.totalAmount, 2),
        row.itemId,
        safeConvertToFixedOrDash(row.sumTotalQuantity, 1),
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    let totalDetailAmount = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalAmount);
    }, 0);

    totalDetailQuantity = Math.round(totalDetailQuantity * 100) / 100;
    totalDetailAmount = Math.round(totalDetailAmount * 100) / 100;

    details.push(["", "", "", "", "", "Totals", safeConvertToFixed(totalDetailQuantity, 1), "", safeConvertToFixed(totalDetailAmount, 2)]);

    let remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    let otherValues = [
      [
        `Partner Inv No: ${nullSafeString(record.partnerInvNo)} \nPartner Inv Date: ${formatDate(record.partnerInvDate)}\t\t\t\t\t\t\t\t\t`,
        `Contractor Inv No: ${nullSafeString(record.contractorInvNo)} \nContractor Inv Date: ${formatDate(
          record.contractorInvDate
        )}\t\t\t\t\t\t\t\t\t`,
        `Closure No: ${nullSafeString(record.closureId)} \nClosure Date: ${
          record.closureId === null ? `` : formatDate(record.closureDate)
        }\t\t\t\t\t\t\t\t\t`,
        `Status: ${this.getRecordStatus(record)}\t\t\t\t\t\t\t\t\t`,
      ],
    ];
    return { title, senderReceiverData, dtHead, details, remarks, otherValues };
  }

  private getBasicDetails(record: any): string[] {
    return [
      `CBS No: ${record.id} \nCBS Date: ${formatDate(record.docDate)} \nItem Type: ${translatePicklistValue(
        record.itemCategory,
        itemCategoryValues
      )}\nFrom Multi Project: ${record.multiProjectFlg ? "True" : "False"}`,
      `Contractor: ${record.docCompanyName} (${record.docCompanyCode}) \nLocator: ${record.docLocatorName} (${
        record.docLocatorCode
      }) \t\tLocator Type: ${translatePicklistValue(record.docLocatorType, locatorTypeValues)}\nMaterial Owner: ${
        record.multiProjectFlg ? record.consuOwnerName : record.docOwnerName
      } (${record.multiProjectFlg ? record.consuOwnerCode : record.docOwnerCode})\nStation: ${record.docStationName} (${
        record.docStateName
      }) \t\tGST No: ${nullSafeString(record.docGSTNo)}`,
    ];
  }

  //=======================================================================================

  private getRecordStatus(record: any) {
    if (record.submit) {
      return "Submitted";
    } else if (record.cancelled) {
      return "Cancelled";
    } else {
      return "Not Submitted";
    }
  }
}

interface CBSheetPdfTaskHandler {
  getTaskWiseDetails(record: any): string[];
}

class FTTHPdfHandler implements CBSheetPdfTaskHandler {
  getTaskWiseDetails(record: any): string[] {
    return [
      `Project Type: ${translatePicklistValue(record.consuProjectType, projectTypeValues)}\nMaterial Type: ${translatePicklistValue(
        record.docMaterialType,
        materialTypeValues
      )}\nCWO Id: ${record.cwoId} \nCWO Date: ${formatDate(record.cwoDate)}`,
      `Site Owner: ${record.consuOwnerName} (${record.consuOwnerCode})\nProject: ${record.consuProjectName} (${
        record.consuProjectCode
      }) \t\tVirtual Locator: ${nullSafeString(record.consuVirtualLocator)}\nCWO No: ${record.cwoNo}\nCWO Type: ${translatePicklistValue(
        record.cwoType,
        workTypeValues
      )}  \t\tWork Area: ${record.workArea}\nRoute Length: ${record.routeLength} \t\tHome Pass: ${record.homePass}`,
    ];
  }
}

class FFPdfHandler implements CBSheetPdfTaskHandler {
  getTaskWiseDetails(record: any): string[] {
    return [
      `Project Type: ${translatePicklistValue(record.consuProjectType, projectTypeValues)}\nMaterial Type: ${translatePicklistValue(
        record.docMaterialType,
        materialTypeValues
      )}\nJob Id: ${record.jobId} \nPO No: ${record.poNo}`,
      `Site Owner: ${record.consuOwnerName} (${record.consuOwnerCode})\nProject: ${record.consuProjectName} (${
        record.consuProjectCode
      }) \t\tVirtual Locator: ${nullSafeString(record.consuVirtualLocator)}\nSite Id: ${record.siteId}\nJob Type: ${translatePicklistValue(
        record.jobType,
        workTypeValues
      )}  \t\t\nRoute Length: ${record.routeLength} \t\t`,
    ];
  }
}

class RegularPdfHandler implements CBSheetPdfTaskHandler {
  getTaskWiseDetails(record: any): string[] {
    return [
      `Project Type: ${translatePicklistValue(record.consuProjectType, projectTypeValues)}\nMaterial Type: ${translatePicklistValue(
        record.docMaterialType,
        materialTypeValues
      )}\nWork Id: ${record.workId} \nPO No: ${record.poNo}`,
      `Site Owner: ${record.consuOwnerName} (${record.consuOwnerCode})\nProject: ${record.consuProjectName} (${
        record.consuProjectCode
      }) \t\tVirtual Locator: ${nullSafeString(record.consuVirtualLocator)}\nSite Id: ${record.siteId}\nWork Type: ${translatePicklistValue(
        record.workType,
        workTypeValues
      )}  \t\t\nWork Date: ${formatDate(record.workDate)} \t\t`,
    ];
  }
}

export const getCBSheetPDFTaskHandler = (projecType: string): CBSheetPdfTaskHandler => {
  switch (projecType) {
    case "FTTH":
      return new FTTHPdfHandler();
    case "FF":
      return new FFPdfHandler();
    default:
      return new RegularPdfHandler();
  }
};

const cbSheetPdfHandler = new CBSheetPdfHandler();
export default cbSheetPdfHandler;
