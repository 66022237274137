import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { apiCall } from "../../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import ConfirmationButtonComponent from "../buttons/ConfirmationButtonComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { canChangeNumericValue, isConstraintSatisfied, isEmptyObj } from "../../services/utils";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ConfigSpec, DateConfigSpec, FieldType, NumberConfigSpec } from "../../types/configuration";
import { getNewValueForField } from "../../services/fieldsUtils";
import { DateRangeConstraint, RangeConstraint } from "../../types/constraint";
import { useSnackBar } from "../providers/SnackBarProvider";
import MultiTextInput from "../inputFields/MultiTextInput";

const configKeyToSpecMap = {};
const ConfigurationScreen = () => {
  const { dispatch: snackBarDispatch } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const [configValues, setConfigValues] = useState({});
  const [configSpecs, setConfigSpecs] = useState([]);
  const [mode, setMode] = useState("view");
  const [isEdited, setIsEdited] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const areValidConfigs = (configValues: {}) => {
    let isValid = true;
    configSpecs.forEach((configSpec: ConfigSpec) => {
      if (isEmptyObj(configValues[configSpec.configKey]) || !constraintSatisfied(configSpec.configKey, configValues[configSpec.configKey])) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  };

  const constraintSatisfied = (name: string, value: any): boolean => {
    let configSpec: ConfigSpec = configKeyToSpecMap[name];
    switch (configSpec.fieldType) {
      case FieldType.NUMBER: {
        if (isNaN(value)) {
          setFormErrors((prevFormErrors) => {
            return { ...prevFormErrors, [name]: `Value should be a whole number` };
          });
          return false;
        }
        let { minValue, maxValue } = configSpec as NumberConfigSpec;
        let constraint: RangeConstraint = { minValue, maxValue, type: "RANGE" };
        if (isConstraintSatisfied(constraint, value, name, setFormErrors, configValues)) {
          clearFormErrors();
        }
        break;
      }
      case FieldType.DATE: {
        let newValue = getNewValueForField("date", null, value);
        let { minDateConstraint, maxDateConstraint } = getMinMaxDateConstraints(configSpec);
        let constraint: DateRangeConstraint = { minDate: minDateConstraint, maxDate: maxDateConstraint, type: "DATE_RANGE" };
        if (isConstraintSatisfied(constraint, newValue, name, setFormErrors, configValues)) {
          clearFormErrors();
        }
        break;
      }
      default:
        break;
    }

    return true;

    function clearFormErrors() {
      if (formErrors[name]) {
        setFormErrors((prevFormErrors) => {
          return { ...prevFormErrors, [name]: null };
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let configSpec: ConfigSpec = configKeyToSpecMap[name];
    if (configSpec.fieldType === FieldType.NUMBER) {
      if (!canChangeNumericValue(false, value)) {
        return;
      }
    }
    constraintSatisfied(name, value);
    setConfigValues({ ...configValues, [name]: value });
    setIsEdited(true);
  };

  const handleSave = async (configValues: {}) => {
    try {
      setLoading(true);
      let response = await apiCall("post", `/api/config`, configValues);

      console.log("Response from saving configurations:", response);
      setIsEdited(false);
      snackBarDispatch({ type: "set", message: "Configuration Updated Successfully", severity: "success" });
    } catch (error) {
      console.error("Error saving configurations:", error);
      snackBarDispatch({ type: "set", message: "Error Occured While Updating Configuration", severity: "error" });
    } finally {
      setLoading(false);
      setMode("view");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await apiCall("get", `/api/config/`);
      const { configSpecs, configValues } = result;
      setConfigSpecs(configSpecs);
      configSpecs.forEach((spec: ConfigSpec) => {
        configKeyToSpecMap[spec.configKey] = spec;
      });
      setConfigValues(configValues);
    } finally {
      setLoading(false);
    }
  };

  const getJsxForConfig = (config: ConfigSpec) => {
    let key = config.configKey;
    let id = config.configKey;
    let name = config.configKey;
    let value = configValues[config.configKey];
    let disabled = mode === "view";
    switch (config.fieldType) {
      case "string":
      case "number": {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              id={id}
              key={key}
              name={name}
              // label={label}
              onChange={(e) => handleChange(e)}
              type={"text"}
              disabled={disabled || config.multiValue}
              error={!isEmptyObj(formErrors[name])}
              helperText={formErrors[name]}
              sx={{
                backgroundColor: "#F0F8FF",
                "& .MuiInputBase-input": {
                  fontSize: "smaller",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "smaller",
                },
                width: "200px",
              }}
              value={value}
              InputLabelProps={{
                shrink: true, // Enable label animation
              }}
              size="small"
              inputProps={{
                min: "0", // Set the minimum value to 0
                step: "any", // Allow decimal input
              }}
              // size="small"
            />
            {config.multiValue && !disabled && (
              <MultiTextInput
                sx={{
                  backgroundColor: "#F0F8FF",
                  "& .MuiInputBase-input": {
                    fontSize: "smaller",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "smaller",
                  },
                  width: "200px",
                }}
                fieldLabel={config.label}
                value={value}
                setValue={(value) => handleChange({ target: { name, value } })}
              />
            )}
          </Stack>
        );
      }
      case "date":
        let { minDate, maxDate } = config as DateConfigSpec;
        let minDateConstraint = minDate ? dayjs(minDate) : null;
        let maxDateConstraint = maxDate ? dayjs(maxDate) : null;
        return (
          <DatePicker
            key={key}
            name={name}
            // label={label}
            value={dayjs(value)}
            disabled={disabled}
            minDate={minDateConstraint}
            maxDate={maxDateConstraint}
            slotProps={{
              textField: {
                // helperText: formErrors[field.name]
                //   ? formErrors[field.name]
                //   : null,
                size: "small",
                error: !isEmptyObj(formErrors[name]),
                helperText: formErrors[name],
              },
            }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "smaller",
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
              backgroundColor: "#F0F8FF",
              width: "200px",
            }}
            format="YYYY-MM-DD"
            onChange={(date) => {
              handleChange({
                target: { name, value: date, type: "date" },
              });
            }}
          />
        );
      case "boolean":
        return (
          <FormControl key={key}>
            <InputLabel
              id={name}
              sx={{
                fontSize: "smaller",
                lineHeight: "1.5", // Adjust to match text alignment
                marginTop: !value ? "-7px" : null,
              }}
            ></InputLabel>
            <Select
              sx={{
                padding: "2px 2px",
                backgroundColor: "#F0F8FF",
                "& .MuiInputBase-input": {
                  fontSize: "smaller",
                  padding: "5px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "smaller",
                },
                width: "200px",
              }}
              error={!isEmptyObj(formErrors[name])}
              size="small"
              disabled={disabled}
              labelId={key}
              id={key}
              // label={label}
              name={name}
              value={value}
              onChange={handleChange}
              key={name}
            >
              {/* <MenuItem value="" disabled>
                {label}
              </MenuItem> */}
              <MenuItem value={"true"}>{"True"}</MenuItem>
              <MenuItem value={"false"}>{"False"}</MenuItem>
            </Select>
          </FormControl>
        );
    }
  };

  const getMinMaxDateConstraints = (configSpec: ConfigSpec) => {
    let { minDate, maxDate } = configSpec as DateConfigSpec;
    let minDateConstraint = minDate ? dayjs(minDate) : null;
    let maxDateConstraint = maxDate ? dayjs(maxDate) : null;
    return { minDateConstraint, maxDateConstraint };
  };

  return (
    <div>
      <Box sx={{ marginTop: 1.5 }}>
        <form>
          <Stack direction="column" alignItems="center" spacing={2}>
            <h4 className="sub-header">Configuration</h4>
            <Paper
              sx={{
                minWidth: "40vw",
                minHeight: "82vh",
                backgroundColor: "#F7F6FF",
                justifyContent: "center",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 2, mb: 4, ml: 4, mr: 4 }} direction="column" alignItems="top" spacing={1}>
                <Grid container spacing={1} marginLeft={1}>
                  <Grid item xs={1} key={1}>
                    <Typography variant="body1" align="left">
                      <strong>Sno</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={5} key={2}>
                    <Typography variant="body1" align="left">
                      <strong>Name</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={3}>
                    <Typography variant="body1" align="left">
                      <strong>Value</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                {loading || !configSpecs.length ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  configSpecs.map((configSpec, index) => (
                    <>
                      <Stack sx={{ ml: 4 }} direction="row" alignItems="top" spacing={2}>
                        <Grid container spacing={1} alignItems="center" key={configSpec.configKey}>
                          <Grid item xs={1} key={configSpec.configKey + "_1"}>
                            {index + 1 + "."}
                          </Grid>
                          <Grid item xs={5} key={configSpec.configKey + "_2"}>
                            <label>{configSpec.label} :</label>
                          </Grid>
                          <Grid item xs={6} key={configSpec.configKey + "_3"}>
                            {getJsxForConfig(configSpec)}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Divider />
                    </>
                  ))
                )}
              </Stack>
            </Paper>
            {mode === "edit" ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                {!isEdited ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setMode("view");
                      setFormErrors({});
                    }}
                    disabled={loading}
                  >
                    Back
                  </Button>
                ) : (
                  <ConfirmationButtonComponent
                    handleSubmit={() => {
                      setMode("view");
                      setFormErrors({});
                      fetchData();
                    }}
                    confirmationTitle="Back"
                    confirmationBody={`Are You Sure,  All Your Unsaved Changes Will Be Lost`}
                    submitBody={configValues}
                    buttonText={"Back"}
                    color="error"
                    disabled={loading}
                  />
                )}
                <ConfirmationButtonComponent
                  handleSubmit={handleSave}
                  confirmationTitle="Update Configurations"
                  confirmationBody="Are You Sure You Wish To Proceed?"
                  submitBody={configValues}
                  buttonText={"Update"}
                  disabled={!isEdited || loading}
                  shouldOpen={areValidConfigs}
                />
              </Stack>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  setMode("edit");
                }}
              >
                Edit
              </Button>
            )}
          </Stack>
        </form>
      </Box>
    </div>
  );
};

export default ConfigurationScreen;
