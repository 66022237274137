import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { formatDate, SYSTEM_START_DATE_FIELD } from "../services/dateUtils";
import { consuProjectTypeValues } from "../standardEntities/project";
import { cwoTypeValues } from "./childWorkOrder";
import { jobTypeValues } from "./job";
import { workTypeValues } from "./work";

export const workClosureFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "workClosureStates",
        defaultValue: null,
        allowUpdate: false,
        lookupParams: [
          {
            key: "companyCode",
            resolveValue: true,
            valueFieldName: "companyCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stateCode",
          },
        ],
        props: {
          width: "17vw",
        },
        linkedFields: [
          {
            name: "cwoId",
            value: "",
          },
          {
            name: "jobId",
            value: "",
          },
          {
            name: "workId",
            value: "",
          },
          {
            name: "atcNo",
            value: "",
          },
          {
            name: "atcDate",
            value: "",
          },
        ],
      },
      {
        name: "stateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "FF",
        values: consuProjectTypeValues,
        disability: {
          type: "OR",
          filters: [
            // { type: "MISSING", field: "companyCode" },
            { type: "MISSING", field: "stateCode" },
          ],
        },
        linkedFields: [
          {
            name: "projectCode",
            value: "",
          },
          {
            name: "projectName",
            value: "",
          },
          {
            name: "companyCode",
            value: "",
          },
          {
            name: "companyName",
            value: "",
          },
          {
            name: "locatorCode",
            value: "",
          },
          {
            name: "locatorName",
            value: "",
          },
          {
            name: "cwoId",
            value: "",
          },
          {
            name: "cwoNo",
            value: "",
          },
          {
            name: "cwoDate",
            value: "",
          },
          {
            name: "cwoType",
            value: "",
          },
          {
            name: "jobId",
            value: "",
          },
          {
            name: "siteId",
            value: "",
          },
          {
            name: "jobType",
            value: "",
          },
          {
            name: "atcNo",
            value: "",
          },
          {
            name: "atcDate",
            value: "",
          },
          {
            name: "workId",
            value: "",
          },
          {
            name: "siteId",
            value: "",
          },
          {
            name: "workType",
            value: "",
          },
        ],
      },
      {
        name: "jobId",
        label: "Job Id",
        type: "lookup",
        lookupType: "job",
        // lookupCategory: "workClosureJob",
        allowUpdate: false,
        defaultValue: "",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
            // {
            //   type: "IN",
            //   field: "companyCode",
            //   resolveValue: true,
            //   valueFieldName: "companyCode",
            // },
          ],
        },
        dependentFields: [
          {
            additionalFieldName: "details",
            lookupFieldName: "siteId",
            fieldName: "siteId",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectCode",
            fieldName: "projectCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectName",
            fieldName: "projectName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "jobType",
            fieldName: "jobType",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "poNo",
            fieldName: "poNo",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "todayDate",
            fieldName: "docDate",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "companyCode",
            fieldName: "companyCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "companyName",
            fieldName: "companyName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorCode",
            fieldName: "locatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorName",
            fieldName: "locatorName",
          },
        ],
        visibility: {
          type: "IN",
          field: "projectType",
          values: ["FF"],
        },
        disability: {
          type: "OR",
          filters: [
            // { type: "MISSING", field: "companyCode" },
            { type: "MISSING", field: "stateCode" },
          ],
        },
      },
      {
        name: "workId",
        label: "Work Id",
        type: "lookup",
        lookupType: "work",
        // lookupCategory: "workClosureJob",
        allowUpdate: false,
        defaultValue: "",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
            // {
            //   type: "IN",
            //   field: "companyCode",
            //   resolveValue: true,
            //   valueFieldName: "companyCode",
            // },
          ],
        },
        dependentFields: [
          {
            additionalFieldName: "details",
            lookupFieldName: "siteId",
            fieldName: "siteId",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectCode",
            fieldName: "projectCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectName",
            fieldName: "projectName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "siteId",
            fieldName: "siteId",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "workType",
            fieldName: "workType",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "poNo",
            fieldName: "poNo",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "todayDate",
            fieldName: "docDate",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "companyCode",
            fieldName: "companyCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "companyName",
            fieldName: "companyName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorCode",
            fieldName: "locatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorName",
            fieldName: "locatorName",
          },
        ],
        visibility: {
          type: "IN",
          field: "projectType",
          values: ["REG"],
        },
        disability: {
          type: "OR",
          filters: [
            // { type: "MISSING", field: "companyCode" },
            { type: "MISSING", field: "stateCode" },
          ],
        },
      },
      {
        name: "cwoId",
        label: "CWO Id",
        type: "lookup",
        lookupType: "child-work-order",
        // lookupCategory: "inwardChildWorkOrder",
        allowUpdate: false,
        defaultValue: "",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
            // {
            //   type: "IN",
            //   field: "companyCode",
            //   resolveValue: true,
            //   valueFieldName: "companyCode",
            // },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "cwoNo",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectCode",
            fieldName: "projectCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "companyCode",
            fieldName: "companyCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "companyName",
            fieldName: "companyName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorCode",
            fieldName: "locatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorName",
            fieldName: "locatorName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectName",
            fieldName: "projectName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "cwoDate",
            fieldName: "cwoDate",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "cwoType",
            fieldName: "cwoType",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "todayDate",
            fieldName: "docDate",
          },
        ],
        visibility: {
          type: "IN",
          field: "projectType",
          values: ["FTTH"],
        },
        disability: {
          type: "OR",
          filters: [
            // { type: "MISSING", field: "companyCode" },
            { type: "MISSING", field: "stateCode" },
          ],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "7vw",
        },
      },
      {
        name: "projectName",
        label: "Project Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "17vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FTTH"],
    },
    childFields: [
      {
        name: "cwoNo",
        label: "CWO No",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FTTH"],
        },
        props: {
          width: "25vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FTTH"],
    },
    childFields: [
      {
        name: "cwoDate",
        label: "CWO Date",
        type: "date",
        autoFilled: true,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FTTH"],
        },
      },
      {
        name: "cwoType",
        label: "CWO Type",
        allowNull: true,
        type: "picklist",
        defaultValue: "",
        autoFilled: true,
        values: cwoTypeValues,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FTTH"],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FF"],
    },
    childFields: [
      {
        name: "siteId",
        label: "Job Site Id",
        type: "text",
        autoFilled: true,
        defaultValue: "",
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
      {
        name: "jobType",
        label: "Job Type",
        autoFilled: true,
        defaultValue: "",
        allowNull: true,
        type: "picklist",
        values: jobTypeValues,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["REG"],
    },
    childFields: [
      {
        name: "siteId",
        label: "Site Id",
        type: "text",
        autoFilled: true,
        defaultValue: "",
        allowNull: true,
      },
      {
        name: "workType",
        label: "Work Type",
        autoFilled: true,
        defaultValue: "",
        allowNull: true,
        type: "picklist",
        values: workTypeValues,
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["REG"],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      // {
      //   name: "companyName",
      //   label: "Company Name",
      //   type: "lookup",
      //   lookupType: "company",
      //   // lookupCategory: "workClosureContractor",
      //   lookupFilter: { type: "AND", filters: [{ type: "IN", field: "contractorFlg", values: [true] }] },
      //   props: {
      //     width: "17vw",
      //   },
      //   dependentFields: [
      //     {
      //       lookupFieldName: "id",
      //       fieldName: "companyCode",
      //     },
      //   ],
      //   linkedFields: [
      //     {
      //       name: "stateCode",
      //       value: "",
      //     },
      //     {
      //       name: "stateName",
      //       value: "",
      //     },
      //     {
      //       name: "cwoId",
      //       value: "",
      //     },
      //     {
      //       name: "jobId",
      //       value: "",
      //     },
      //     {
      //       name: "atcNo",
      //       value: "",
      //     },
      //     {
      //       name: "atcDate",
      //       value: "",
      //     },
      //   ],
      //   allowNull: false,
      // },
      {
        name: "companyName",
        label: "Company Name",
        type: "text",
        props: {
          width: "17vw",
        },
        autoFilled: true,
        allowNull: false,
      },
      {
        name: "companyCode",
        label: "Company Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: false,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "locatorCode",
        label: "Locator",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: false,
      },
      {
        name: "locatorName",
        label: "Locator Name",
        type: "text",
        props: {
          width: "17vw",
        },
        autoFilled: true,
        allowNull: false,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "projectType",
      values: ["FF"],
    },
    childFields: [
      {
        name: "atcNo",
        label: "ATC No",
        type: "number",
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
      {
        name: "atcDate",
        label: "ATC Date",
        type: "date",
        constraints: [
          {
            type: "DATE_RANGE",
            minDateScript: {
              operation: "minDate",
              globalFields: [SYSTEM_START_DATE_FIELD],
            },
            beforeNow: true,
          },
        ],
        disability: {
          type: "NIN",
          field: "projectType",
          values: ["FF"],
        },
      },
    ],
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    props: {
      width: "25vw",
    },
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "id",
        label: "Closure No",
        displayOnly: true,
        allowNull: true,
        type: "text",
      },
      {
        name: "docDate",
        label: "Closure Date",
        type: "date",
        allowNull: true,
        displayOnly: true,
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "cancelled",
        label: "Cancelled",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
        disabledForCreation: true,
        // disability: {
        //   type: "OR",
        //   filters: [
        //     {
        //       type: "MISSING",
        //       field: "id",
        //     },
        //   ],
        // },
      },
    ],
  },
];

export const workClosureColumns = [
  {
    name: "id",
    label: "Closure No",
    type: "text",
  },
  {
    name: "docDate",
    label: "Closure Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "projectType",
    label: "Type",
    type: "text",
    format: (value) => translatePicklistValue(value, consuProjectTypeValues),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
  },
  {
    name: "taskType",
    label: "Task Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues, ...workTypeValues]),
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workClosureExcelColumns = [
  {
    name: "id",
    label: "Closure No",
    type: "text",
  },
  {
    name: "docDate",
    label: "Closure Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, consuProjectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
  },
  {
    name: "taskType",
    label: "Task Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues, ...workTypeValues]),
  },
  {
    name: "poNo",
    label: "PO No",
    type: "text",
  },
  {
    name: "siteId",
    label: "Job Site Id",
    type: "text",
  },
  {
    name: "atcNo",
    label: "ATC No",
    type: "text",
  },
  {
    name: "atcDate",
    label: "ATC Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const workClosureSearchColumns = [
  {
    name: "closureId",
    label: "Closure No",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "projectType",
    label: "Type",
    type: "picklist",
    defaultValue: "",
    values: consuProjectTypeValues,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "workClosure.projectCode",
    label: "Project",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  // {
  //   name: "projectName",
  //   label: "Project Name",
  //   type: "text",
  // },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "taskId",
    label: "Task Id",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "companyName",
    label: "Company",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
